import React, { Component, Fragment } from 'react';
import { AutoSizer } from 'react-virtualized';
import { ReactSVGPanZoom } from 'react-svg-pan-zoom';
import DimmerLoader from 'components/DimmerLoader';
import PropTypes from 'prop-types';
import SvgLoader from 'components/SvgLoader';

export default class DraggableSVG extends Component {
  static propTypes = {
    svg: PropTypes.string.isRequired,
    onLoad: PropTypes.func,
    fitToCenter: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  state = {
    innerSvg: null,
  };

  componentDidUpdate (prevProps, prevState) {
    if (prevState.innerSvg !== this.state.innerSvg) {
      if (this.ref) {
        const { onLoad, fitToCenter } = this.props;
        onLoad && onLoad(this.ref);
        fitToCenter && this.ref.fitToViewer('center', 'center');
      }
    }
  }

  handleLoad = (data) => {
    const { innerSvg, width, height } = this.parseSvg(data);
    this.setState({
      innerSvg,
      width,
      height,
    });
  };

  parseSvg = (svg) => {
    const div = document.createElement('div');
    div.innerHTML = svg;
    const domSvg = div.querySelector('svg');
    return {
      innerSvg: domSvg.innerHTML,
      width: domSvg.getAttribute('width'),
      height: domSvg.getAttribute('height'),
    };
  };

  initRef = (Viewer) => {
    this.ref = Viewer;
  };

  handleChangeTool = (tool) => this.setState({ tool });

  render () {
    const {
      innerSvg,
      width,
      height,
      tool: currentTool,
    } = this.state;
    const externalTool = (this.props.disabled && 'none') || null;
    const className = this.ref && `tool--${externalTool || currentTool}`;
    return (
      <SvgLoader path={this.props.svg} onLoad={this.handleLoad}>
        {({ loading, loaded }) => (
          <Fragment>
            <DimmerLoader active={loading}/>
            <AutoSizer>
              {(size) => (
                loaded && <ReactSVGPanZoom
                  miniaturePosition={'none'}
                  ref={this.initRef}
                  className={className}
                  onChangeTool={this.handleChangeTool}
                  tool={externalTool}
                  scaleFactorMax={1.6}
                  width={size.width}
                  height={size.height}
                  background='#fff'
                  toolbarPosition='right'
                  detectAutoPan={false}
                >
                  <svg width={width} height={height}>
                    <g dangerouslySetInnerHTML={{ __html: innerSvg }}/>
                  </svg>
                </ReactSVGPanZoom>
              )}
            </AutoSizer>
          </Fragment>
        )}
      </SvgLoader>
    );
  }
}
