import { get } from 'server/implementation';
export default {
  /**
   * @param {string} min  optional
   * @param {string} max  optional
   * @param {integer} client_id  client's id from get_client_info
   * @param {Date} select_from  start date of event (default: new Date())
   * @param {Date} select_to  finish date of event
   * @param {boolean} is_for_report  need for report
   */
  get: (params) => get('get-orders-list', params),
  /**
   * @param {integer} order_id  order_id
   */
  order: (params) => get('get-order', params),
  /**
   * param {string} client_id  client's id from get_client_info
   */
  orderCreate: (params) => get('order-create', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} order_id  id
   * @param {integer} ident_type_id  type for pay
   * @param {boolean} is_cash_active  active cash app
   * @param {integer} check_no  number check
   */
  promoterOrderPay: (params) => get('promoter-order-pay', params),
  /**
   * @param {integer} order_id  order_id
   */
  promoterOrderConfirm: (params) => get('promoter-mnf-order-confirm', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} order_id  id
   * @param {string($length=1)} copy_order  need create copy order Available values : Y, N
   * @param {boolean} is_cash_active  active cash app
   * @param {integer} check_no  number check
   */
  promoterOrderCancel: (params) => get('promoter-order-cancel', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} order_entity_id  Order Record (Ticket) Id
   * @param {integer} ident_type_id  pay type
   * @param {boolean} is_cash_active  active cash app
   * @param {integer} check_no  number check
   */
  promoterOrderEntityReturn: (params) => get('promoter-order-entity-return', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} order_id  order_id
   */
  promoterMnfOrderCancel: (params) => get('promoter-mnf-order-cancel', params),
  /**
   * @param {integer} order_entity_id  Order Record (Ticket) Id
   */
  promoterMnfOrderEntityReturn: (params) => get('promoter-mnf-order-entity-return', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} order_id  order_id
   * @param {integer} ticket_template_type_id Ticket Template Id is
   * @param {string($length=50)} email send email address
   */
  getOrderInPdf: (params) => get('order-get-ticket', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} order_entity_id  Order Record (Ticket) Id
   * @param {string($length=50)} email send email address
   */
  getOrderOneTicketInPdf: (params) => get('order-entity-get-ticket', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} order_entity_id  Order Record (Ticket) Id
   * @param {integer} ticket_template_type_id Ticket Template Id is
   * @param {string($integer:id1,id2,...,idN)} order_entities Identifiers of orders (tickets) records
   * (required if order_entity_id is not passed or empty)
   */
  setTicketTemplate: (params) => get('ticket-set-template', params),
  /**
   * @param {string} client_id  client's id from get_client_info
   * @param {integer} order_id  Order ID
   */
  orderDelete: (params) => get('order-delete', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} order_entity_id  Order Record (Ticket) Id
   */
  orderEntityDelete: (params) => get('order-entity-delete', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} order_id  order_id
   */
  orderCancelPrinting: (params) => get('order-cancel-printing', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} order_entity_id  Order Record (Ticket) Id
   */
  orderEntityCancelPrinting: (params) => get('order-entity-cancel-printing', params),
};
