import api from 'server';
import { promiseByObject } from 'utils/helpers';
import { REFERENCES } from 'redux/types';

export const getReferences = (object) => ({
  type: REFERENCES.GET,
  promise: promiseByObject(object),
});

export const getStartReferences = () => getReferences({
  'cities': api.references.cities(),
  'countries': api.references.countries(),
  'placesList': api.references.placesList({
    min: 0,
    max: 100,
  }),
  'regions': api.references.regions(),
  'typesObjectList': api.references.typesObjectList({
    min: 0,
    max: 100,
  }),
  'eventCategories': api.references.eventCategories(),
  'eventTypeId': api.references.eventTypeId(),
  'templateTypeList': api.references.templateTypeList(),
});
