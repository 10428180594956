import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { union, xor, isArray } from 'lodash';
// import { Popup } from 'semantic-ui-react';
import { getSeparatedPlace, isBBoxIntersect } from 'utils/helpers';
import api from 'server';
import DraggableSVG from './DraggableSVG';
// import { ReactSVGPanZoom } from 'react-svg-pan-zoom';

const SELECTION_TYPES = {
  MOUSE: 'mouse',
  RECT: 'rect',
};

const deselectPlace = function () {
  if (this[ 'placeSelected' ]) {
    this[ 'placeSelected' ] = false;
    this.setAttributeNS(null, 'fill', null);
  }
  return this;
};

const selectPlace = function (color) {
  if (!this[ 'placeSelected' ]) {
    this[ 'placeSelected' ] = true;
    this.setAttributeNS(null, 'fill', color);
  }
  return this;
};

const bindSelectMethods = (el) => {
  el[ 'deselectPlace' ] = deselectPlace;
  el[ 'selectPlace' ] = selectPlace;
  el[ 'placeSelected' ] = false;
};

export default class Scheme extends Component {
  static propTypes = {
    svg: PropTypes.string.isRequired,
    references: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    selectedFill: PropTypes.string,
    fitToCenter: PropTypes.bool,
    instanceRef: PropTypes.func,
  };

  static defaultProps = {
    selectedFill: '#000',
  };

  state = {
    selecting: false,
    checkPlace: false,
    seatLocksFill: false,
    checkPlaceLock: {},
    mousePosition: {},
    price: null,
    open: false,
    color: null,
    scrollTop: 0,
    height: 0,
    groupFreePlace: null,
  };

  componentDidMount () {
    const { references, match } = this.props;
    const { eventId } = match.params;
    references.load({
      pricesList: api.events.getPricesList({ events: eventId })
        .then((response) => response),
      seatLocksList: api.places.getSeatLock()
        .then((response) => response),
    });
    window.addEventListener('scroll', this.handleScroll);

  }

  componentUnmount () {
    this.unbindEvents();
    window.removeEventListener('scroll', this.handleScroll);
  }

  selected = [];

  ref = React.createRef();

  dragInstance = React.createRef();

  handleScroll = () => {
    const scrollTop = window.scrollY;
    this.setState({
      scrollTop: scrollTop,
    });
  };

  getMousePosition = (event) => {
    const CTM = this.svg.getScreenCTM();
    return {
      x: (event.clientX - CTM.e) / CTM.a,
      y: (event.clientY - CTM.f) / CTM.d,
    };
  };

  deselect = (places) => {
    if (isArray(places)) {
      for (let i = 0; i < places.length; i++) {
        const el = places[ i ];
        el.deselectPlace();
      }
      this.handleSelect(xor(this.selected, places));
    } else if (typeof places === 'number') {
      const i = places;
      this.selected[ i ].deselectPlace();
      this.selected.splice(i, 1);
      this.handleSelect();
    }
  };

  handleSelect (selected) {
    const { onSelect } = this.props;
    selected && (this.selected = selected);
    onSelect && onSelect(this.selected);
  }

  handleMouseUp = () => {
    if (this.start) {
      this.rect.style.visibility = 'hidden';
      const selected = this.getSelectedPlaces();
      if (selected) {
        this.handleSelect(union(this.selected, selected));
      }
    }
    this.start = null;
    this.end = null;
  };

  handleMouseDown = (event) => {
    const { selecting } = this.state;
    if (selecting) {
      this.start = this.getMousePosition(event);
    }
  };

  drawSelectionRect (event) {
    let startX = 0;
    let startY = 0;

    this.end = this.getMousePosition(event);
    if (this.start.x < this.end.x) {
      startX = this.start.x;
    } else {
      startX = this.end.x;
    }

    if (this.start.y < this.end.y) {
      startY = this.start.y;
    } else {
      startY = this.end.y;
    }

    this.rect.style.visibility = 'visible';

    const width = Math.abs(this.start.x - this.end.x);
    const height = Math.abs(this.start.y - this.end.y);
    this.drawRect(width, height, startX, startY);
  }

  checkMouseTarget (event) {
    const el = event.target;
    const name = el.getAttribute('name');
    const { selecting } = this.state;
    if (name) {
      const index = this.selected.indexOf(el);
      if (index === -1) {
        el.selectPlace(this.props.selectedFill);
        this.selected.push(el);
        this.handleSelect();
      } else if (selecting !== SELECTION_TYPES.MOUSE) {
        this.deselect(index);
      }
    }
  }

  handleMouseMove = (event) => {
    if (this.start) {
      switch (this.state.selecting) {
        case SELECTION_TYPES.RECT:
          return this.drawSelectionRect(event);
        case SELECTION_TYPES.MOUSE:
          return this.checkMouseTarget(event);
        default:
          return null;
      }
    }
  };

  getSelectedPlaces = () => {
    if (this.start) {
      const rectBox = this.rect.getBoundingClientRect();
      const bounds = this.places.map((el) => (
        el[ 'placeSelected' ] ? null : el.getBoundingClientRect()
      ));

      return this.places.filter((el, index) => {
        if (el[ 'placeSelected' ]) {
          return true;
        } else {
          const selected = isBBoxIntersect(rectBox, bounds[ index ]);
          const fillColor = selected ? this.props.selectedFill : null;
          return fillColor && el.selectPlace(fillColor);
        }
      });
    }
  };

  handleClick = (event) => this.checkMouseTarget(event);

  createRect = () =>
    document.createElementNS('http://www.w3.org/2000/svg', 'rect');

  setRectAttr = (key, value) => this.rect.setAttributeNS(null, key, value);

  drawRect = (width, height, x, y) => {
    this.setRectAttr('width', parseFloat(width));
    this.setRectAttr('height', parseFloat(height));
    this.setRectAttr('x', parseFloat(x));
    this.setRectAttr('y', parseFloat(y));
  };

  setup = () => {
    this.svg = this.ref.current.querySelector('svg');
    this.rect = this.createRect();
    this.places = Array.from(this.svg.querySelectorAll('[name][id]'));
    this.places.forEach(bindSelectMethods);
    this.rect.setAttribute('class', 'selection');
    this.svg.appendChild(this.rect);
    this.selected = [];
    this.unbindEvents();
    this.bindEvents();
  };

  handleLoad = () => {
    this.setup();
  };

  setSelection = (type) => (
    (type !== this.state.selecting) && this.setState({ selecting: type })
  );

  handleDocumentKeyDown = (event) => {
    if (event.ctrlKey || event.metaKey) {
      this.setSelection(SELECTION_TYPES.RECT);
    } else if (event.shiftKey) {
      this.setSelection(SELECTION_TYPES.MOUSE);
    }
  };

  parsePlaceName (name) {
    const { references: { getString } } = this.props;
    return getSeparatedPlace(name)
      .map((type) => {
        const name = getString('typesObjectList', type[ 0 ]);
        return type[ 1 ] && (`${name}: ${type[ 1 ]}`);
      }).filter(Boolean);
  }

  handleDocumentKeyUp = () => this.setSelection(null);

  findColorList = (fill) => {
    const { references } = this.props;
    const color = 'color';
    return references.data.seatLocksList.length && references.data.seatLocksList.reduce((acc, item) => {
      item[ color ] === fill && acc.push(item);
      return acc;
    }, []);
  };

  handleDocumentMouseOver = (event) => {
    const { references } = this.props;
    const el = event.target;
    if (!event.ctrlKey || !event.metaKey) {
      if (el.getAttribute('name')) {
        const placeName = this.parsePlaceName(el.getAttribute('name'));
        const freePlaces = el.getAttribute('gsc_fs');
        const priceParams = references.get('pricesList', el.parentElement.getAttribute('price_id'));
        const mousePosition = this.getMousePosition(event);
        // const seatLocksFill = el.parentElement.getAttribute('fill');
        // const seatLocksList = references.data.seatLocksList.map((key, el) => {
        //   return <span key={key}>{el.name}</span>;
        // });
        const seatLocksList = this.findColorList(el.parentElement.getAttribute('fill'));
        // console.log(seatLocksList);
        this.setState({
          checkPlace: placeName,
          groupFreePlace: freePlaces,
          seatLocksFill: seatLocksList,
          price: priceParams.price || 0,
          color: priceParams.color,
          mousePosition: mousePosition,
          open: true,
        });
      }
    }
  };

  handleDocumentMouseOut = () => {
    this.setState({
      checkPlace: false,
      price: null,
      mousePosition: {},
      open: false,
      color: null,
    });
  };

  unbindEvents = () => {
    document.removeEventListener('keydown', this.handleDocumentKeyDown);
    document.removeEventListener('keyup', this.handleDocumentKeyUp);
    document.addEventListener('mouseover', this.handleDocumentMouseOver);
    document.addEventListener('mouseout', this.handleDocumentMouseOut);
  };

  bindEvents = () => {
    document.addEventListener('keydown', this.handleDocumentKeyDown);
    document.addEventListener('keyup', this.handleDocumentKeyUp);
    document.addEventListener('mouseover', this.handleDocumentMouseOver);
    document.addEventListener('mouseout', this.handleDocumentMouseOut);
  };

  render () {
    const {
      selecting,
      checkPlace,
      price,
      color,
      scrollTop,
      height,
      seatLocksFill,
      groupFreePlace,
    } = this.state;
    return (
      <div className="plan-map">
        <div className='place-wrapper'>
          <div className={scrollTop > height ? 'place place-fixed' : 'place'}>
            {checkPlace ? <Fragment>
              <div className='place-header'><p>Место на площадке: </p></div>
              <div className='place-text'>
                {checkPlace.length > 3 ? <div className='place-text__place'>
                  <p><b>{checkPlace[ 0 ]}</b></p>
                  <p><b>{checkPlace[ 1 ]}</b></p>
                  {groupFreePlace && <p><b>Свободных мест:</b> {groupFreePlace}</p>}
                  <p>{checkPlace[ 2 ]}, {checkPlace[ 3 ]}</p>
                  {seatLocksFill.map((el) => (
                    <p key={el.id} className='type-color-mini'>
                      <b>{el.name}</b>
                      <span className="place-text__type-color" style={{ background: el.color }}/>
                    </p>
                  ))}
                </div> : <div className='place-text__place'>
                  <p><b>{checkPlace[ 0 ]}</b></p>
                  {groupFreePlace && <p><b>Свободных мест:</b> {groupFreePlace}</p>}
                  <p>{checkPlace[ 1 ]}, {checkPlace[ 2 ]}</p>
                  {seatLocksFill.map((el) => (
                    <p key={el.id} className='type-color-mini'>
                      <b>{el.name}</b>
                      <span className="place-text__type-color" style={{ background: el.color }}/>
                    </p>
                  ))}
                </div>}
                {price !== 0 &&
                <div className='place-text__type-box'>
                  <span className="place-text__type-color" style={{ background: color }}/>
                  <span>{price} BYN</span>
                </div>
                }
              </div>
            </Fragment> : <div className='place-header'><p>Место на площадке не выбрано</p></div>}
          </div>
        </div>
        <div
          ref={this.ref}
          onClick={this.handleClick}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          onMouseMove={this.handleMouseMove}
          className='plan-map__svg'
        >
          <DraggableSVG
            svg={this.props.svg}
            onLoad={this.handleLoad}
            disabled={!!selecting}
            fitToCenter={this.props.fitToCenter}
          />
        </div>
      </div>
    );
  }
}
