import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Link extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    onPress: PropTypes.func,
    classNameBtn: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };

  render () {
    const {
      href,
      classNameBtn,
      text,
      onClick,
    } = this.props;
    return (
      <a href={href} className={classNameBtn ? `${classNameBtn}` : ''} onClick={onClick}>{text}</a>
    );
  }
}

export default Link;
