import { formatDateSelect } from 'constants/dateFormats';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import ReactTable, { ReactTableDefaults } from 'react-table';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { parseSearch } from 'utils/helpers';
import DimmerLoader from 'components/DimmerLoader';
import TableWithPagination from 'components/TableWithPagination';
import FilterPanel from 'components/FilterPanel/index';
import Checkbox from 'components/Checkbox';
import withReferences from 'hocs/withReferences';
import { getEvents, getHistoryParams } from 'redux/actions/events';
import getColumns from './columns';

const CheckboxTable = checkboxHOC(ReactTable);
const today = moment().format(formatDateSelect);

const CustomTbodyComponent = (props) => (
  <ReactTableDefaults.TbodyComponent {...props}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </ReactTableDefaults.TbodyComponent>
);

const ReactTableCheckbox = (props) => <Checkbox
  {...props}
  className='dddd'
  style={{ width: 50, maxWidth: 50, flex: '50 0 auto' }}
  onClick={(e) => {
    e.stopPropagation();
    props.onClick(props.id, e.shiftKey, props.row);
  }}
/>;

const ReactTableAllCheckbox = (props) => <Checkbox
  id={parseFloat('000')}
  {...props}
  onClick={(e) => {
    e.stopPropagation();
    props.onClick(props.id, e.shiftKey, props.row);
  }}
/>;

class ReportsList extends Component {
  static propTypes = {
    getEvents: PropTypes.func.isRequired,
    getHistoryParams: PropTypes.func,
    clearHistoryParams: PropTypes.func,
    list: PropTypes.array.isRequired,
    references: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    allCount: PropTypes.number,
    params: PropTypes.object,
  };

  state = {
    selection: [],
    selectAll: false,
    saleFrom: [today],
    list: [],
    allCount: 300,
  };

  componentDidMount () {
    const {
      getHistoryParams,
      getEvents,
      history: { location },
      match: { params },
    } = this.props;
    const oldParams = parseSearch(location.search);
    const eventParams = {
      ...oldParams,
      min: 1,
      max: this.state.allCount,
    };
    if (location.search) {
      getHistoryParams(oldParams);
      getEvents(eventParams);
    } else if (params.type === '1' || params.type === '2') {
      location.search ? this.props.getEvents(eventParams) : this.props.getEvents({ select_from: this.state.saleFrom, min: 1, max: this.state.allCount });
    } else {
      getEvents();
    }
  }

  getData = () => {
    const { list, references } = this.props;
    const isLoaded = references.loaded && list.length;
    if (isLoaded) {
      const data = list.map((item) => {
        const _id = item.id;
        return {
          _id,
          ...item,
        };
      });
      return data;
    }
  };

  handlePageChange = ({ min, max }) => {
    const params = { min, max, ...this.props.params };
    if (this.props.history.location.search) {
      this.props.getEvents(params);
    } else {
      this.props.getEvents({ min, max });
    }
  };

  toggleSelection = (key, shift, row) => {
    let selection = [ ...this.state.selection ];
    const saleFrom = [ ...this.state.saleFrom ];
    const keyIndex = selection.indexOf(row.id);
    const selectDate = row.sale_from;
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
      this.setState((prevState) => ({
        ...prevState,
        selection,
        saleFrom,
        params: {
          ...prevState.params,
          events: keyIndex,
        },
      }));
    } else {
      selection.push(row.id);
      saleFrom.push(selectDate);
    }
    this.setState((prevState) => ({
      ...prevState,
      selection,
      saleFrom,
    }));
  };

  setRef = (r) => {
    this.checkboxTable = r;
  };

  toggleAll = () => {
    const selectAll = !this.state.selectAll;
    const selection = [];
    const saleFrom = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(item._original.id);
        saleFrom.push(item._original.sale_from);
      });
    }
    this.setState({
      selectAll,
      selection,
      saleFrom,
    });
  };

  isSelected = (key) => this.state.selection.includes(key);

  onLocation = (id) => {
    const { match, history } = this.props;
    const { type } = match.params;
    const { selection, saleFrom } = this.state;
    const moments = saleFrom.map((d) => moment(d));
    const minDate = moment.min(moments);
    const today = moment().format(formatDateSelect);
    if (type === '1' || type === '2') {
      history.push({
        pathname: `/reports/${type}/events/${selection[ 0 ]}`,
        search: `?sale_from=${minDate._i}&sale_to=${today}`,
        state: this.state.selection,
      });
    } else {
      history.push(`/reports/${type}/events/${id}`);
    }
  };

  render () {
    const { list, references, loading, allCount, history, params, match } = this.props;
    const columns = getColumns.call(this);
    const isLoaded = references.loaded && list.length;
    const {
      toggleSelection,
      toggleAll,
      isSelected,
    } = this;

    const {
      selectAll,
    } = this.state;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        const selected = r ? this.isSelected(r.original._id) : false;
        return {
          style: {
            backgroundColor: selected ? '#f4f6f7' : 'inherit',
          },
        };
      },
    };
    const type = match.params.type;

    return (
      <Fragment>
        <FilterPanel
          references={references}
          history={history}
          filterParams={params}
          showInput={true}
          showDataPicker={true}
          showTextInfo={true}
        />
        <div className="list reports">
          <div className="container">
            <Fragment>
              <div className="ku"/>
              {isLoaded ? (type === '1' || type === '2') ? <Fragment>
                <div className="x-scroll">
                  <div className="table-box">
                    <CheckboxTable
                      ref={this.setRef}
                      TbodyComponent={CustomTbodyComponent}
                      SelectInputComponent={ReactTableCheckbox}
                      SelectAllInputComponent={ReactTableAllCheckbox}
                      data={this.getData()}
                      columns={columns}
                      defaultPageSize={list.length}
                      showPagination={false}
                      noDataText='Записей нет'
                      className='table-height__reports'
                      {...checkboxProps}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <button
                        className={'btn btn-blue'}
                        onClick={history.goBack}
                      >Вернуться к типу
                      </button>
                    </div>

                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <Button
                        type="submit"
                        className="btn btn-orange"
                        onClick={this.onLocation}
                      >
                        Перейти к формированию отчета
                      </Button>
                    </div>
                  </div>
                </div>
              </Fragment> : <TableWithPagination
                data={list}
                columns={columns}
                pageSize={10}
                allCount={allCount}
                onPageChange={this.handlePageChange}
                manualPagination={true}
                className="-highlight"
                defaultSorting={[
                  {
                    id: 'date_time_from',
                    asc: true,
                  },
                ]}
                getTrGroupProps={(state, rowInfo) => {
                  if (rowInfo !== undefined) {
                    return {
                      onClick: () => {
                        this.onLocation(rowInfo.original.id);
                      },
                      style: {
                        cursor: 'pointer',
                      },
                    };
                  }
                }}
              /> : null}
              <DimmerLoader active={loading}/>
            </Fragment>
          </div>
        </div>
      </Fragment>
    );
  }
}

const ReportsListWithRefs = withReferences(ReportsList);

function mapStateToProps (state) {
  const { events, historyParams: { params } } = state;
  return {
    ...events,
    params,
  };
}

const mapDispatchToProps = {
  getEvents,
  getHistoryParams,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsListWithRefs);
