import React from 'react';
import { getSeparatedTime } from 'utils/helpers';
import { Link } from 'react-router-dom';

export default function () {
  return [
    {
      Header: 'Имя',
      accessor: 'descriptions[0][name]',
      minWidth: 335,
      sortable: false,
    },
    {
      Header: 'Время проведения',
      accessor: 'date_time_from',
      minWidth: 190,
      resizable: false,
      Cell: ({ value }) => <p className='data-time'>{getSeparatedTime(value)}</p>,
    },
    {
      Header: 'Площадка',
      accessor: 'place_id',
      minWidth: 200,
      resizable: false,
      Cell: ({ value }) => (<p>{this.props.references.getString('placesList', value)}</p>),
    },
    {
      Header: 'Действия',
      accessor: 'id',
      minWidth: 165,
      sortable: false,
      resizable: false,
      Cell: ({ value }) => <Link to={`/events/${value}`} className={'btn btn-sm btn-blue'}>Перейти к плану зала</Link>,
    },
  ];
}
