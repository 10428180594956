import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Title extends Component {
  static propTypes = {
    title: PropTypes.string,
    type: PropTypes.any,
    children: PropTypes.any,
    headingClass: PropTypes.string,
  };

  getTitle () {
    const { type, title, children } = this.props;
    const string = children || title;
    switch (type) {
      case 'h2':
        return <h2>{string}</h2>;
      case 'h1':
      default:
        return <h1>{string}</h1>;
    }
  }

  render () {
    const { headingClass } = this.props;
    return (
      <div className={headingClass}>
        {this.getTitle()}
      </div>
    );
  }
}

export default Title;
