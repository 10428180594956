/* eslint-disable no-throw-literal */
import { AUTH } from 'redux/types';
import api from 'server';
import { clearClientLS, getClientLS } from 'server/client';
import { LS } from 'utils/localStorage';

export const login = (params = getClientLS()) => (dispatch) => {
  if (!params) {
    return dispatch(logout());
  }
  const promise = async () => {
    let sid;
    try {
      sid = await api.auth.login(params);
    } catch (e) {
      dispatch(logout());
      e && dispatch(authError(e));
      throw e;
    }
    dispatch(getClient({ ...params, sid }));
    return sid;
  };
  dispatch({
    type: AUTH.LOGIN,
    promise: promise(),
  });
};

const fetchClient = async (params = getClientLS(), dispatch) => {
  if (!params) {
    dispatch(logout());
    throw { error: 'api.auth.getClientInfo params is empty' };
  }

  try {
    const { sid, ...rest } = params;
    const client = await api.auth.getClientInfo(sid);
    LS.set(
      'auth',
      { ...rest, sid, clientId: client.client_id },
    );
    return client;
  } catch (e) {
    dispatch(authError());
    throw e;
  }
};

export const getClient = (params) => (dispatch) => {
  const promise = fetchClient(params, dispatch);
  dispatch({
    type: AUTH.GET_CLIENT,
    promise,
  });
  return promise;
};

export const logout = () => {
  clearClientLS();
  return {
    type: AUTH.LOGOUT,
  };
};

export const authError = (error) => {
  // clearClientLS();
  return {
    type: AUTH.ERROR,
    error,
    errorMessage: error,
  };
};
