import React from 'react';
import { Link } from 'react-router-dom';
import { getSeparatedTime } from 'utils/helpers';

export default function () {
  return [
    {
      Header: 'Номер заказа',
      accessor: 'id',
      Cell: ({ value }) => <div>{value}</div>,
      minWidth: 250,
      resizable: false,
    },
    {
      Header: 'Время проведения',
      accessor: 'date_time_from',
      minWidth: 190,
      resizable: false,
      Cell: ({ value }) => <p className='data-time'>{getSeparatedTime(value)}</p>,
    },
    {
      Header: 'Пользователь',
      minWidth: 200,
      resizable: false,
    },
    {
      Header: 'Сеть',
      minWidth: 165,
      resizable: false,
    },
    {
      Header: 'Действия',
      accessor: 'id',
      minWidth: 165,
      sortable: false,
      resizable: false,
      Cell: ({ value }) => <Link to={`/orders/${value}`} className={'btn btn-sm btn-blue'}> Просмотреть билеты</Link>,
    },
  ];
}
