import React, { Component } from 'react';
import { login, logout, getClient } from 'redux/actions/auth';
import { withRouter } from 'react-router-dom';
import LoginForm from 'components/LoginForm';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DimmerLoader from 'components/DimmerLoader';

class Authenticated extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    getClient: PropTypes.func.isRequired,
    children: PropTypes.any,
  };

  componentDidMount () {
    return this.setupAuth();
  }

  setupAuth () {
    this.props.getClient()
      .catch(() => this.props.login());
  }

  handleSubmit = (fields) => this.props.login(fields);

  render () {
    const { auth, children } = this.props;
    if (auth.loaded) {
      return (
        auth.client
          ? children
          : <LoginForm onSubmit={this.handleSubmit} auth={auth}/>
      );
    } else {
      return <DimmerLoader active={true}/>;
    }
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = { login, logout, getClient };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authenticated));
