import React, { Component } from 'react';
import logo from 'img/logo.svg';

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="logo">
            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={logo} className="App-logo" alt="logo"/>
            </a>
          </div>
        </div>
      </footer>
    );
  }
};