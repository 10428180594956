import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Message, Button } from 'semantic-ui-react';
import Field from 'components/Field';
import { connect } from 'react-redux';
import { getOrderSearch } from 'redux/actions/orders';

class Search extends Component {
  static propTypes = {
    getOrderSearch: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    onButtonClick: PropTypes.func,
    list: PropTypes.array,
    errorMessage: PropTypes.bool,
  };

  state = {
    filterTitle: 'Поиск заказов',
    params: {},
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      params: {
        [ name ]: value,
      },
    });
    this.props.onInputChange(value);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onButtonClick();
    this.props.getOrderSearch(this.state.params);
  };

  render () {
    const { filterTitle, params } = this.state;
    const error = this.props.errorMessage;
    return (
      <div className="filter">
        <div className="container">
          <Form autoComplete="off" onSubmit={this.handleSubmit} error={error}>
            <div className="box">
              <div className="box-head">
                <div className="box-title">{filterTitle}</div>
              </div>
              <div className='box-body'>
                <div className="form-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <Form.Field className='form-group' error={error}>
                          <Field
                            control='input'
                            type='number'
                            className="input"
                            name="order_id"
                            value={params.name}
                            placeholder="Введите номер серии (заказа) без ведущих нулей"
                            onInput={this.inputNumber}
                            onChange={this.handleChange}
                          />
                        </Form.Field>
                        <Message error content='Такого мероприятия нет'/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-button">
                  <div className="row">
                    <div className="col-12">
                      <Button type="submit" className="btn btn-orange" onClick={this.handleSubmit}>
                        Поиск
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
function mapStateToProps (state) {
  return {
    ...state.orders,
  };
}

const mapDispatchToProps = {
  getOrderSearch,
};
export default connect(mapStateToProps, mapDispatchToProps)(Search);
