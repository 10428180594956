import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Title from 'components/Title';

class ReportTypes extends Component {
  render () {
    return (
      <Fragment>
        <div className="list">
          <div className="container">
            <Title
              type='h2'
              headingClass="heading"
              title="Выберите тип отчета"
            />
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="table-box">
                  <div className="mini-report-info">
                    <div className="radio-text">
                      <h2>Отчет по мероприятиям - общий </h2>
                      <span>Данный отчет дает полный обзор о количестве проданных и забронированных билетах</span>
                    </div>
                    <Link to='/reports/1/events' className={'btn btn-orange'}> Далее</Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="table-box">
                  <div className="mini-report-info">
                    <div className="radio-text">
                      <h2>Отчет по мероприятиям - стандартный</h2>
                      <span>Данный отчет отображает проданные билеты по ценовым категориям, скидкам и книгам</span>
                    </div>
                    <Link to='/reports/2/events' className={'btn btn-orange'}> Далее</Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12 col-md-12">
                <div className="table-box">
                  <div className="mini-report-info">
                    <div className="radio-text">
                      <h2>Статистика по местам</h2>
                      <span>
                        Данный отчет отображает проданные/забронированные/доступные места в разрезе ценовых категорий
                      </span>
                    </div>
                    <Link to='/reports/3/events' className={'btn btn-orange'}> Далее</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ReportTypes;
