import { COMMON, ORDERS } from 'redux/types';

const defaultState = {
  list: [],
  invitation: false,
  manufacturing: false,
  statusId: null,
  loading: false,
  loaded: false,
  error: null,
  errorMessage: false,
};

export default function orderList (state = defaultState, action = {}) {
  switch (action.type) {
    case ORDERS.ORDER_ITEM + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ORDERS.ORDER_ITEM + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
        errorMessage: true,
      };
    }
    case ORDERS.ORDER_ITEM + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.response[ 'tickets' ],
        invitation: action.response[ 'is_invitation' ],
        manufacturing: action.response[ 'is_manufacturing' ],
        statusId: action.response[ 'status_id' ],
        errorMessage: action.errorMessage,
      };
    }
    case ORDERS.ORDER_CANCEL_PRINTING + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ORDERS.ORDER_CANCEL_PRINTING + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
        errorMessage: true,
      };
    }
    case ORDERS.ORDER_CANCEL_PRINTING + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.response[ 'tickets' ],
        invitation: action.response[ 'is_invitation' ],
        manufacturing: action.response[ 'is_manufacturing' ],
        statusId: action.response[ 'status_id' ],
        errorMessage: action.errorMessage,
      };
    }
    case ORDERS.ORDER_ENTITY_CANCEL_PRINTING + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ORDERS.ORDER_ENTITY_CANCEL_PRINTING + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
        errorMessage: true,
      };
    }
    case ORDERS.ORDER_ENTITY_CANCEL_PRINTING + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.response[ 'tickets' ],
        invitation: action.response[ 'is_invitation' ],
        manufacturing: action.response[ 'is_manufacturing' ],
        statusId: action.response[ 'status_id' ],
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
}
