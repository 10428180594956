import { get } from 'server/implementation';

export default {
  eventCategories: (params) => get('get-event-categories-list', params),
  eventTypeId: (params) => get('get-event-types-list', params),
  templateTypeList: (params) => get('get-ticket-template-types-list', params),
  countries: (params) => get('get-countries-list', params),
  regions: (params) => get('get-regions-list', params),
  typesObjectList: (params) => get('get-plan-obj-types-list', params),
  cities: (params) => get('get-cities-list', params),
  placesList: (params) => get('get-places-list', params),
};
