import { connect } from 'react-redux';
import Login from 'components/Login';
import { login, logout } from 'redux/actions/auth';

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = {
  login,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
