import React, { Component, Fragment } from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { Button, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import api from 'server';
import withReferences from 'hocs/withReferences';
import {
  getOrderItem,
  promoterOrderCancel,
  promoterMnfOrderCancel,
  promoterMnfOrderEntityReturn,
  orderCancelPrinting,
  orderEntityCancelPrinting,
} from 'redux/actions/orderTickets';
import { PromiseAll } from 'utils/helpers';
import DimmerLoader from 'components/DimmerLoader';
import Checkbox from 'components/Checkbox';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import Title from 'components/Title';
import getColumns from './columns';

const CheckboxTable = checkboxHOC(ReactTable);

const CustomTbodyComponent = (props) => (
  <ReactTableDefaults.TbodyComponent {...props}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </ReactTableDefaults.TbodyComponent>
);

const ReactTableCheckbox = (props) => <Checkbox
  {...props}
  onClick={(e) => {
    e.stopPropagation();
    props.onClick(props.id, e.shiftKey, props.row);
  }}
/>;

const ReactTableAllCheckbox = (props) => <Checkbox
  id={parseFloat('000')}
  {...props}
  onClick={(e) => {
    e.stopPropagation();
    props.onClick(props.id, e.shiftKey, props.row);
  }}
/>;

class OrderTickets extends Component {
  static propTypes = {
    getOrderItem: PropTypes.func.isRequired,
    promoterOrderCancel: PropTypes.func.isRequired,
    promoterMnfOrderCancel: PropTypes.func.isRequired,
    promoterMnfOrderEntityReturn: PropTypes.func.isRequired,
    orderCancelPrinting: PropTypes.func.isRequired,
    orderEntityCancelPrinting: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    references: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    invitation: PropTypes.bool.isRequired,
    manufacturing: PropTypes.bool.isRequired,
    statusId: PropTypes.number,
    errorMessage: PropTypes.bool,
  };

  state = {
    selection: [],
    selectAll: false,
    open: false,
    params: {},
  };

  componentDidMount () {
    const { id } = this.props.match.params;
    this.props.getOrderItem({ order_id: id });
  }

  getData = () => {
    const { list, references } = this.props;
    const isLoaded = list.length && references.loaded;
    if (isLoaded) {
      const data = list.map((item) => {
        const _id = item.id;
        return {
          _id,
          ...item,
        };
      });
      return data;
    }
  };

  toggleSelection = (key) => {
    let selection = [ ...this.state.selection ];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
      this.setState((prevState) => ({
        ...prevState,
        selection,
        params: {
          ...prevState.params,
          order_id: keyIndex,
        },
      }));
    } else {
      selection.push(key);
    }
    this.setState((prevState) => ({
      ...prevState,
      selection,
    }));
  };

  toggleAll = () => {
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(item._original.id);
      });
    }
    this.setState({
      selectAll,
      selection,
    });
  };

  isSelected = (key) => this.state.selection.includes(key);

  showModal = () => this.setState({ open: true });

  hideModal = () => this.setState({ open: false });

  onRemoveSuccess = () => {
    const { id } = this.props.match.params;
    this.props.getOrderItem({ order_id: id });
    this.setState({ loading: false, selection: [] });
  };

  removeOrder = async () => {
    const { id } = this.props.match.params;
    if (this.withPay()) {
      await this.props.promoterMnfOrderCancel({
        order_id: id,
      });
    } else {
      await this.props.promoterOrderCancel({
        order_id: id,
        copy_order: false,
        is_cash_active: false,
      });
    }
    this.onRemoveSuccess();
  };

  removeItems = (e) => {
    const { selection } = this.state;
    e.preventDefault();
    if (selection && selection.length) {
      this.setState({ loading: true });
      if (this.withPay()) {
        PromiseAll(
          selection.map((el) => (
            api.orders.promoterMnfOrderEntityReturn({
              order_entity_id: el,
            })
          )),
        ).then(this.onRemoveSuccess);
      } else {
        PromiseAll(
          selection.map((el) => (
            api.orders.promoterOrderEntityReturn({
              order_entity_id: el,
              ident_type_id: 'CASH',
              is_cash_active: false,
            })
          )),
        ).then(this.onRemoveSuccess);
      }
    }
  };

  setRef = (r) => (this.checkboxTable = r);

  templateTypeData = (data) => data.map((item) => ({ value: item.id, text: item.name }));

  collectData = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      params: {
        ...prevState.params,
        [ name ]: value,
      },
    }));
  };

  withPay = () => this.props.list.some((item) => !item[ 'is_invitation' ] || !item[ 'is_manufacturing' ] ||
    !item[ 'is_cashless' ] || !item[ 'is_ioc' ]);

  handleChangeStatus = async (e) => {
    const { history, orderCancelPrinting } = this.props;
    const { id } = this.props.match.params;
    const { selection } = this.state;
    e.preventDefault();
    if (this.withPay()) {
      if (selection && selection.length) {
        this.setState({ loading: true });
        PromiseAll(
          selection.map((el) => (
            api.orders.orderEntityCancelPrinting({
              order_entity_id: el,
            })
          )),
        ).then(this.onRemoveSuccess);
      } else {
        await orderCancelPrinting({
          order_id: id,
        });
      }
      history.push(`/orders/${id}/ticket`);
    } else {
      const respOrderCreate = await api.orders.promoterOrderCancel({
        order_id: id,
        copy_order: true,
        is_cash_active: false,
      });
      await api.orders.promoterOrderPay({
        order_id: respOrderCreate.id,
        ident_type_id: 'CASH',
        is_cash_active: false,
      });
      history.push(`/orders/${respOrderCreate.id}/ticket`);
    }
  };

  render () {
    const { list, references, loading, statusId } = this.props;
    const isLoaded = list.length && references.loaded;
    const columns = getColumns.call(this);
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll, open } = this.state;
    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        const selected = r ? this.isSelected(r.original._id) : false;
        return {
          style: {
            backgroundColor: selected ? '#f4f6f7' : 'inherit',
          },
        };
      },
    };
    return (
      <Fragment>
        <div className="basket content">
          <div className="container">
            <div className="row">
              <div className="wrapper-info">
                <Title headingClass="heading" type='h2' title='Билеты из заказа'/>
                {isLoaded ? <Fragment>
                  <div className="ku"/>
                  <div className="x-scroll">
                    <div className="table-box">
                      <CheckboxTable
                        ref={this.setRef}
                        TbodyComponent={CustomTbodyComponent}
                        SelectInputComponent={ReactTableCheckbox}
                        SelectAllInputComponent={ReactTableAllCheckbox}
                        data={this.getData()}
                        columns={columns}
                        defaultPageSize={list.length}
                        showPagination={false}
                        noDataText='Записей нет'
                        className='table-height__basket'
                        {...checkboxProps}
                      />
                    </div>
                  </div>
                  <div className="table-box">
                    {!(statusId === -1 || statusId === -2 || statusId === -3) ? <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <Button className={'btn btn-orange'} onClick={this.removeOrder}>Отменить заказ</Button>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <Button className={'btn'} onClick={this.removeItems}>Вернуть билеты из заказа</Button>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <Modal
                            trigger={
                              <Button
                                className='btn btn-blue'
                                disabled={!isLoaded}
                                onClick={this.showModal}
                              >
                                Перепечатать билеты из заказа
                              </Button>
                            }
                            closeIcon={
                              <div
                                className='close-model'
                                onClick={this.hideModal}>
                                <span/>
                                <span/>
                              </div>}
                            centered={true}
                            className='basket-modal'
                            dimmer='blurring'
                            open={open}
                          >
                            <Modal.Content>
                              <Fragment>
                                <h2 className='content-title'>Перепечатать билеты из заказа</h2>
                                <p className='content-info'>
                                  Если при печати у Вас произошла ошибка.<br/>
                                  Подтвердите действие,
                                  после этого, у Вас появится возможность
                                  напечатать билеты заново!
                                </p>
                                <Button className='btn btn-orange' onClick={this.handleChangeStatus}>
                                  Подтвердить
                                </Button>
                                <Button className='btn btn-default' onClick={this.hideModal}>
                                  Отмена
                                </Button>
                              </Fragment>
                            </Modal.Content>
                          </Modal>
                        </div>
                      </div>
                    </div> : <div className="row">
                      <div className="col-lg-8">
                        <p className='content-info'>Ваш заказ отменен, дополнительные действия не возможны</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          className={'btn btn-orange'}
                          onClick={this.props.history.goBack}
                        >
                          Вернуться к списку заказов
                        </button>
                      </div>
                    </div>}
                  </div>
                </Fragment> : <div className="row">
                  <div className="col-lg-8">
                    <p className='content-info'>Ваш заказ удален, дополнительные действия не возможны</p>
                  </div>
                  <div className="col-lg-4">
                    <button
                      className={'btn btn-orange'}
                      onClick={this.props.history.goBack}
                    >
                      Вернуться к списку заказов
                    </button>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
        <DimmerLoader active={loading}/>
      </Fragment>
    );
  }
}

const ListOrderItemsWithRefs = withReferences(OrderTickets);

function mapStateToProps (state) {
  return {
    ...state.orderTickets,
  };
}

const mapDispatchToProps = {
  getOrderItem,
  promoterOrderCancel,
  promoterMnfOrderCancel,
  promoterMnfOrderEntityReturn,
  orderCancelPrinting,
  orderEntityCancelPrinting,
};
export default connect(mapStateToProps, mapDispatchToProps)(ListOrderItemsWithRefs);
