import { formatDateSelect } from 'constants/dateFormats';
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Radio, Form, Checkbox } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { getEventPricesList, getEvents } from 'redux/actions/events';
import Title from 'components/Title';
import { paramsSerializer } from 'utils/helpers';
import { ticketProTickets, organizerTickets } from './initialData';

class CreateReport extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getEventPricesList: PropTypes.func.isRequired,
    getEvents: PropTypes.func.isRequired,
    price: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
  };

  state = {
    isToggleOn: true,
    startDate: null,
    endDate: null,
    title: '',
    fields: {
      'report-set': '3-1',
      'show_sold_by_ticketpro': true,
      'show_manufactured_by_ticketpro': true,
      'show_invitations_by_ticketpro': true,
      'show_sold_by_promoter': true,
      'show_manufactured_by_promoter': true,
      'show_invitations_by_promoter': true,

    },
    selection: [],
  };

  async componentDidMount () {
    const { match, getEventPricesList, getEvents } = this.props;
    const { id } = match.params;
    await getEventPricesList({ event_id: id });
    await getEvents({ events: id });
  }

  getPriceList = (data, getText) => (
    data.map((el) => {
      const { descriptions, id } = el;
      return {
        value: id,
        text: getText ? getText(el) : descriptions[ 0 ].name,
      };
    })
  );

  collectData = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [ name ]: value,
      },
    }));
  };

  handleChangeStart (date) {
    this.setState({
      startDate: date,
    });
    this.collectData('date_time_from', date.startOf('day').format(formatDateSelect));
  }

  handleChangeEnd (date) {
    this.setState({
      endDate: date,
    });
    this.collectData('date_time_to', date.endOf('day').format(formatDateSelect));
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { fields } = this.state;
    if (name === 'report-type' && fields[ 'report-set' ] && fields[ 'report-set' ].length) {
      this.setState({
        fields: {
          [ name ]: value,
        },
      });
    } else {
      this.setState({
        fields: {
          ...fields,
          [ name ]: value,
        },
      });
    }
  };

  handleChangeCheckbox = (event) => {
    const { name, checked } = event.target;
    const { fields } = this.state;
    const selectedTicketPro = ticketProTickets.map((item) => (item.name));
    const selectedOrganizer = organizerTickets.map((item) => (item.name));
    if (name === fields[ `${selectedTicketPro}` ] && fields[ `${selectedOrganizer}` ] && fields[ 'report-set' ] && fields[ 'report-set' ].length) {
      this.setState({
        fields: {
          [ name ]: checked || false,
        },
      });
    } else {
      this.setState({
        fields: {
          ...fields,
          [ name ]: checked || false,
        },
      });
    }
  };

  getLocation = () => {
    const { fields } = this.state;
    const { match, list, history } = this.props;
    const { id } = match.params;
    const typeReport = fields[ 'report-set' ];
    const saleFrom = list.length && list.map((item) => item.sale_from);
    const saleTo = list.length && list.map((item) => item.sale_to);
    const reportParams = paramsSerializer({
      event_id: id,
      show_sold_by_ticketpro: fields[ 'show_sold_by_ticketpro' ],
      show_manufactured_by_ticketpro: fields[ 'show_manufactured_by_ticketpro' ],
      show_invitations_by_ticketpro: fields[ 'show_invitations_by_ticketpro' ],
      show_sold_by_promoter: fields[ 'show_sold_by_promoter' ],
      show_manufactured_by_promoter: fields[ 'show_manufactured_by_promoter' ],
      show_invitations_by_promoter: fields[ 'show_invitations_by_promoter' ],
      sale_from: fields[ 'report-set' ] === '3-2' ? fields[ 'date_time_from' ] : saleFrom,
      sale_to: fields[ 'report-set' ] === '3-2' ? fields[ 'date_time_to' ] : saleTo,
      report_format: 8,
    });
    history.push({
      pathname: `/reports/report/${id}/${typeReport}`,
      search: reportParams,
    });
  };

  render () {
    const {
      startDate,
      endDate,
      fields,
    } = this.state;

    return (
      <div className="report-type">
        <div className="container">
          <Title type='h1' headingClass='heading' title='Статистика по местам'/>
          <div className="table-box">
            <Form autoComplete="off">
              <div className="radio-elem">
                <Form.Field className="checkbox">
                  <Radio
                    id='3-1'
                    name='report-set'
                    value='3-1'
                    checked={fields[ 'report-set' ] === '3-1'}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <div className="radio-text">Полный<br/>
                  <span>Отчет с начала продаж</span>
                </div>
              </div>
              <div className={classNames('box radio-set-inner inner-margin', {
                active: fields[ 'report-set' ] === '3-1',
              })}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="info-reports">
                      <p>Для получения отчета <b>"Статистика по местам"</b> виберите исходные данные отчета</p>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="info-reports">
                          <p>Билеты Тикетпро</p>
                        </div>
                        <div className="date-wrapper">
                          {
                            ticketProTickets.map((item, key) => (
                              <div className="radio-elem elem-mini" key={key}>
                                <Form.Field>
                                  <Checkbox
                                    id={`0-${item.id}`}
                                    label={item.title}
                                    name={item.name}
                                    defaultChecked
                                    onClick={this.handleChangeCheckbox}
                                  />
                                </Form.Field>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="info-reports">
                          <p>Билеты организатора</p>
                        </div>
                        <div className="date-wrapper">
                          {
                            organizerTickets.map((item, key) => (
                              <div className="radio-elem elem-mini" key={key}>
                                <Form.Field>
                                  <Checkbox
                                    id={`0-${item.id}`}
                                    label={item.title}
                                    name={item.name}
                                    defaultChecked
                                    onClick={this.handleChangeCheckbox}
                                  />
                                </Form.Field>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="radio-elem">
                <Form.Field className="checkbox">
                  <Radio
                    id='3-2'
                    value='3-2'
                    name='report-set'
                    checked={fields[ 'report-set' ] === '3-2'}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <div className="radio-text">Период времени<br/>
                  <span>Для отчета за определенный период необходимо выбрать
                    <b> Дата с</b>  и <b>Дата до</b>
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className="col-lg-12">
                  <div className={classNames('box radio-set-inner', {
                    active: fields[ 'report-set' ] === '3-2',
                  })}>
                    <div className="date-wrapper">
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              selected={startDate}
                              selectsStart
                              startDate={startDate}
                              endDate={endDate}
                              placeholderText="Дата с"
                              onChange={(value) => {
                                this.handleChangeStart(value);
                              }}
                            />
                            <span className="icon-calendar"/>
                            <div className="help-block"/>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              selected={endDate}
                              selectsEnd
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                              placeholderText="Дата до"
                              onChange={(value) => this.handleChangeEnd(value)}
                            />
                            <span className="icon-calendar"/>
                            <div className="help-block"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="info-reports">
                          <p>Для получения отчета <b>"Статистика по местам"</b> виберите исходные данные отчета</p>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="info-reports">
                              <p>Билеты Тикетпро</p>
                            </div>
                            <div className="date-wrapper">
                              {
                                ticketProTickets.map((item, key) => (
                                  <div className="radio-elem elem-mini" key={key}>
                                    <Form.Field>
                                      <Checkbox
                                        id={`1-${item.id}`}
                                        label={item.title}
                                        name={item.name}
                                        defaultChecked
                                        onClick={this.handleChangeCheckbox}
                                      />
                                    </Form.Field>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="info-reports">
                              <p>Билеты организатора</p>
                            </div>
                            <div className="date-wrapper">
                              {
                                organizerTickets.map((item, key) => (
                                  <div className="radio-elem elem-mini" key={key}>
                                    <Form.Field>
                                      <Checkbox
                                        id={`1-${item.id}`}
                                        label={item.title}
                                        name={item.name}
                                        defaultChecked
                                        onClick={this.handleChangeCheckbox}
                                      />
                                    </Form.Field>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div className="form-row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <button
                  className={'btn btn-blue'}
                  onClick={this.props.history.goBack}
                >Вернуться к мероприятиям
                </button>
              </div>

            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <Button
                  type="submit"
                  className="btn btn-orange"
                  onClick={this.getLocation}
                >
                  Показать отчет
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  const { events: { price, list } } = state;
  return {
    price,
    list,
  };
}

const mapDispatchToProps = {
  getEventPricesList,
  getEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReport);
