import { get } from 'server/implementation';

export default {
  getSeatLock: () => get('get-seat-locks-list'),

  /**
   * @param {string} tickets  integer:id1, ...integer:idN/integer:countN
   */
  seatsUnlock: (tickets) => get('seats-unlock', { tickets }),

  /**
   * @param {string} ticket_id  id of seat from svg
   * @param {string} tickets_and_counts  integer:id1/integer:count1, ...integer:idN/integer:countN
   * @param {string} seat_locks  id1, id2, ..., idN
   * @param {string} seat_lock_id  id
   */
  seatsLock: (params) => get('seats-lock', params),
  /**
   * @param {string} ticket_id  id of seat from svg
   */
  deleteSeatLock: (params) => get('seat-unlock', params),
  /**
   * @param {string} ticket_id  id of seat from svg
   * @param {string} min  optional
   * @param {string} max  optional
   */
  getSeatInGroup: (params) => get('get-seats-in-group-list', params),
  /**
   * @param {string} eventId  id event
   */
  getPlaceSVG: (eventId) => get('get-promoter-svg', { event_id: eventId }),
  getPlaceFullInfo: (eventId) => get('get-promoter-svg-full', { event_id: eventId }),
  /**
   * @param {string} tickets_and_counts  integer:id1/integer:count1, ...integer:idN/integer:countN
   * @param {string} prices  id1, id2, ..., idN
   * @param {boolean} is_without_money  false
   */
  ticketsReserve: (params) => get('tickets-reserve', params),
};
