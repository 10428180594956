import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Title from 'components/Title';

class TableContainer extends Component {
  static propTypes = {
    title: PropTypes.any.isRequired,
    children: PropTypes.any,
  };

  render () {
    const {
      title,
      children,
    } = this.props;
    return (
      <div className="list">
        <div className="container">
          <Title headingClass="heading" type='h2' title={title}/>
          {children}
        </div>
      </div>
    );
  }
}

export default TableContainer;
