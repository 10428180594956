import { AUTH, COMMON } from 'redux/types';

const defaultState = {
  client: null,
  loading: false,
  loaded: false,
  error: null,
  errorMessage: null,
};

export default function dictionary (state = defaultState, action = {}) {
  switch (action.type) {
    case AUTH.LOGIN + COMMON.START:
      return {
        ...state,
        loading: true,
      };

    case AUTH.LOGIN + COMMON.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case AUTH.LOGOUT:
      return {
        ...state,
        client: null,
        error: null,
        loaded: true,
      };

    case AUTH.GET_CLIENT + COMMON.START:
      return {
        ...state,
        loading: true,
      };

    case AUTH.GET_CLIENT + COMMON.SUCCESS:
      return {
        ...state,
        client: action.response,
        loading: false,
        loaded: true,
      };

    case AUTH.GET_CLIENT + COMMON.ERROR:
      return {
        ...state,
        client: null,
        loading: false,
        error: action.error,
        errorMessage: action.errorMessage,
      };

    case AUTH.ERROR:
      return {
        ...state,
        client: null,
        error: action.error,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
}
