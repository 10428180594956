import React from 'react';
import { getSeparatedTime, getSeparatedPlace } from 'utils/helpers';

export default function () {
  return [
    {
      Header: 'Мероприятие',
      accessor: 'event_id',
      minWidth: 250,
      resizable: false,
      Cell: ({ value }) => {
        const { references } = this.props;
        return <span>{references.getString('events', value)}</span>;
      },
    },
    {
      Header: 'Дата/Время',
      accessor: 'date_time_from',
      minWidth: 130,
      resizable: false,
      Cell: ({ value, original }) => {
        const { references } = this.props;
        const event = references.get('events', original.event_id);
        return (
          event ? <p className='data-time' key={event.id}>
            {getSeparatedTime(event[ 'date_time_from' ])}
          </p> : ''
        );
      },
    },
    {
      Header: 'Площадка',
      accessor: 'place_id',
      minWidth: 200,
      resizable: false,
      Cell: ({ value, original }) => {
        const { references } = this.props;
        const event = references.get('events', original.event_id);
        return (
          event ? <span key={event.id}>
            {references.getString('placesList', event.place_id)}
          </span> : ''
        );
      },
    },
    {
      Header: 'Цена единицы',
      accessor: 'price',
      minWidth: 170,
      resizable: false,
    },
    {
      Header: 'Место',
      accessor: 'ticket_name',
      minWidth: 170,
      Cell: ({ value }) => {
        const { references } = this.props;
        return (
          getSeparatedPlace(value).map((el) =>
            <p key={`${el[ 0 ]}_${el[ 1 ]}`}>
              <span>{references.getString('typesObjectList', el[ 0 ])}: </span>
              {el[ 1 ]}
            </p>,
          )
        );
      },
    },
  ];
}
