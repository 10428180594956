import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Checkbox extends Component {
  static propTypes = {
    id:  PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    name: PropTypes.number,
    onClick: PropTypes.func,
    innerRef: PropTypes.func,
  };

  static defaultProps = {
    checked: false,
    disabled: false,
  };

  render () {
    const {
      innerRef,
      id,
      required,
      onClick,
      checked,
    } = this.props;

    return (
      <div className="checkbox">
        <input
          id={id}
          type="checkbox"
          name={'selected-' + id || null}
          required={required || null}
          checked={checked}
          onClick={onClick || null}
          onChange={() => void (0)}
          ref={innerRef}
        />
        <label htmlFor={id}/>
      </div>
    );
  }
}
