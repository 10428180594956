import { formatDateSelect } from 'constants/dateFormats';
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import SelectCustom from 'components/Select';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import FilterTitle from 'components/FilterPanel/FilterTitle';
import PropTypes from 'prop-types';
import { getEvents, clearHistoryParams, getHistoryParams } from 'redux/actions/events';
import { paramsSerializer, parseSearch } from 'utils/helpers';
import Field from 'components/Field';
import DimmerLoader from 'components/DimmerLoader';

const today = moment().format(formatDateSelect);
const todaySlash = moment().format('DD/MM/YYYY');


class FilterEvents extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    showSelect: PropTypes.bool,
    showInput: PropTypes.bool,
    showDataPicker: PropTypes.bool,
    showTextInfo: PropTypes.bool,
    filters: PropTypes.any,
    getEvents: PropTypes.any,
    getHistoryParams: PropTypes.func,
    clearHistoryParams: PropTypes.func,
    updateFilters: PropTypes.any,
    loading: PropTypes.bool,
    filterParams: PropTypes.object,
  };

  static defaultProps = {
    showSelect: false,
    showInput: false,
    showDataPicker: false,
    showTextInfo: false,
  };

  constructor (props) {
    super(props);
    this.state = {
      params: {
        select_from: today
      },
      category: '',
      position: '',
      placeOther: '',
      isToggleOn: true,
      startDate: null,
      todaySlash: todaySlash,
      endDate: null,
      ...this.props.filters,
      title: 'Выберите мероприятие, или воспользуйтесь фильтром',
    };
    this.updateFilters = this.updateFilters.bind(this);
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.filterParams !== nextProps.filterParams) {
      this.setState({
        params: { ...nextProps.filterParams },
      });
    }
  }

  collectData = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      params: {
        ...prevState.params,
        [ name ]: value,
      },
    }));
  };

  handleChangeStart = (date) => {
    this.setState({
      startDate: date,
      todaySlash: date.format('DD/MM/YYYY'),
    });
    this.collectData('select_from', date.startOf('day').format(formatDateSelect));
  };

  handleChangeEnd = (date) => {
    this.setState({
      endDate: date,
    });
    this.collectData('select_to', date.endOf('day').format(formatDateSelect));
  };

  onClick = () => {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  };

  handleSubmit = (e) => {
    const {
      history,
    } = this.props;
    e.preventDefault();
    history.push({
      search: `?${paramsSerializer(this.state.params)}`,
    });
    const params = parseSearch(history.location.search);
    console.log(params);
    const eventParams = {
      ...params,
      min: 1,
      max: 300,
    };
    if (history.location.search) {
      this.props.getEvents(eventParams);
      this.props.getHistoryParams(params);
    } else {
      this.props.getEvents(this.state.params);
      this.props.getHistoryParams(this.state.params);
    }
  };

  onReset = () => {
    const {
      history,
    } = this.props;
    history.push({
      search: ``,
    });
    this.setState({
      params: {},
      startDate: null,
      endDate: null,
      todaySlash: '',
    });
    this.props.getEvents({ select_from: today });
    this.props.clearHistoryParams();
  };

  formatSelectedData = (data) => data.map((item) => ({ value: item.id, text: item.descriptions.name }));

  updateFilters = () => this.props.updateFilters(this.state);

  render () {
    const {
      isToggleOn,
      startDate,
      endDate,
      title,
      params,
    } = this.state;

    const { props, loading } = this;
    const isLoaded = props.references.loaded;
    return (
      isLoaded ? <div className="filter">
        <div className="container">
          <Form action="#" autoComplete="off" onSubmit={this.handleSubmit}>
            <div className="box">
              <Fragment>
                <FilterTitle title={title} toggleOn={isToggleOn} callback={this.onClick}/>
                <div className={`${isToggleOn ? 'box-body' : 'box-body close-box'}`} data-toggle="box">
                  <div className="form-body">
                    <div className="row">
                      {props.showInput &&
                      <div className={props.showSelect ? 'col-lg-6' : 'col-lg-12'}>
                        <Form.Field className='form-group'>
                          <Field
                            type='input'
                            className="input"
                            name="name_filter"
                            value={params.name_filter || ''}
                            placeholder={'Событие'}
                            onChange={(e) => this.collectData('name_filter', e.target.value)}
                          />
                          <div className="help-block"/>
                        </Form.Field>
                      </div>
                      }
                      {props.showSelect &&
                      <div className="col-lg-6">
                        <div className="form-group">
                          <SelectCustom
                            className={'selectCustom'}
                            optionArray={this.formatSelectedData(props.references.get('eventCategories'))}
                            placeholder={'Выберите категорию'}
                            value={params[ 'event_categories' ]}
                            name={'event_categories'}
                            onValueChange={(value) => this.collectData('event_categories', value)}
                          />
                          <div className="help-block"/>
                        </div>
                      </div>
                      }
                      {props.showDataPicker &&
                      <div className={props.showSelect ? 'col-lg-6' : 'col-lg-12'}>
                        <div className="input-daterange">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <DatePicker
                                  dateFormat="DD/MM/YYYY"
                                  selected={startDate}
                                  selectsStart
                                  startDate={startDate}
                                  value={this.state.todaySlash}
                                  endDate={endDate}
                                  placeholderText="Период с"
                                  locale="ru"
                                  onChange={(value) => {
                                    this.handleChangeStart(value);
                                  }}
                                  popperModifiers={{
                                    flip: {
                                      behavior: [ 'bottom' ],
                                    },
                                    preventOverflow: {
                                      enabled: false,
                                    },
                                    hide: {
                                      enabled: false,
                                    },
                                  }}
                                />
                                <span className="icon-calendar"/>
                                <div className="help-block"/>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <DatePicker
                                  dateFormat="DD/MM/YYYY"
                                  selected={endDate}
                                  selectsEnd
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  placeholderText="Период до"
                                  onChange={(value) => this.handleChangeEnd(value)}
                                  popperModifiers={{
                                    flip: {
                                      behavior: [ 'bottom' ],
                                    },
                                    preventOverflow: {
                                      enabled: false,
                                    },
                                    hide: {
                                      enabled: false,
                                    },
                                  }}
                                />
                                <span className="icon-calendar"/>
                                <div className="help-block"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      {props.showSelect &&
                      <Fragment>
                        <div className="col-lg-3 col-md-6">
                          <div className="form-group">
                            <SelectCustom
                              className={'selectCustom'}
                              optionArray={this.formatSelectedData(props.references.get('cities'))}
                              placeholder={'Место нахождения'}
                              value={params[ 'cities' ]}
                              name={'cities'}
                              onValueChange={(value) => this.collectData('cities', value)}
                            />
                            <div className="help-block"/>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="form-group">
                            <SelectCustom
                              className={'selectCustom'}
                              optionArray={this.formatSelectedData(props.references.get('placesList'))}
                              placeholder={'Место проведения'}
                              value={params[ 'venues' ]}
                              name={'venues'}
                              onValueChange={(value) => this.collectData('venues', value)}
                            />
                            <div className="help-block"/>
                          </div>
                        </div>
                      </Fragment>
                      }
                    </div>
                  </div>
                  <div className="form-button">
                    <div className="row">
                      <div className="col-6">
                        <Button onClick={this.onReset} type="reset" className="btn">Сбросить</Button>
                      </div>
                      <div className="col-6">
                        <Button type="submit" className="btn btn-orange">Поиск</Button>
                      </div>
                    </div>
                  </div>
                </div>
                {props.showTextInfo &&
                <div className="reports-form-footer">
                  <p>Фильтр данных устанавливается на текущую дату по умолчанию. Если вы хотите выбрать прошедшее
                    мероприятие,
                    введите значение в поле <b>Дата с.</b>
                  </p>
                  <b>ВАЖНО:</b> Результат поиска ограничен 50 мероприятиями - если данное количество превышается, то
                  отображается
                  первые 50.
                </div>}
              </Fragment>
            </div>
          </Form>
        </div>
      </div> : <DimmerLoader active={loading}/>
    );
  }
}

export default connect(null, { getEvents, getHistoryParams, clearHistoryParams })(FilterEvents);
