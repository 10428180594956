import { EVENTS } from 'redux/types';
import api from 'server';

export const getEvents = (params) => ({
  type: EVENTS.GET,
  promise: api.events.get(params),
});
export const getEventPricesList = (params) => ({
  type: EVENTS.GET_PRICE_LIST,
  promise: api.events.getEventPricesList(params),
});

export const getEventTicketTemplateList = (params) => ({
  type: EVENTS.GET_TICKET_TEMPLATE_LIST,
  promise: api.events.getEventTicketTemplateList(params),
});

export const getHistoryParams = (params) => ({
  type: EVENTS.GET_HISTORY_PARAMS,
  params,
});

export const clearHistoryParams = () => ({
  type: EVENTS.CLEAR_HISTORY_PARAMS,
});
export const clear = () => ({
  type: EVENTS.CLEAR,
});
