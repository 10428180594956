import React, { Component, Fragment } from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { Button, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import getColumns from 'components/BasketList/columns';
import Checkbox from 'components/Checkbox';
import Title from 'components/Title';
import withReferences from 'hocs/withReferences';
import { connect } from 'react-redux';
import { getBasket, removeTicket } from 'redux/actions/basket';
import { promoterOrderPay } from 'redux/actions/orders';
import DimmerLoader from 'components/DimmerLoader';
import { PromiseAll } from 'utils/helpers';
import api from 'server';

const CheckboxTable = checkboxHOC(ReactTable);

const CustomTbodyComponent = (props) => (
  <ReactTableDefaults.TbodyComponent {...props}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </ReactTableDefaults.TbodyComponent>
);

const ReactTableCheckbox = (props) => <Checkbox
  {...props}
  onClick={(e) => {
    e.stopPropagation();
    props.onClick(props.id, e.shiftKey, props.row);
  }}
/>;

const ReactTableAllCheckbox = (props) => <Checkbox
  id={parseFloat('000')}
  {...props}
  onClick={(e) => {
    e.stopPropagation();
    props.onClick(props.id, e.shiftKey, props.row);
  }}
/>;

class BasketList extends Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    references: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    removeTicket: PropTypes.func.isRequired,
    getBasket: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    promoterOrderPay: PropTypes.func.isRequired,
  };

  state = {
    selection: [],
    selectAll: false,
    open: false,
    loadingData: false,
  };

  async componentDidMount() {
    const resp = await this.props.getBasket();
    const { references } = this.props;
    this.setState({ loadingData: true });
    const idEvents = resp['basket_entities'].map((item) => item.event_id).join(', ');
    references.load({
      events: api.events.get({ events: idEvents })
        .then((response) => response.events),
    });
  }

  getData = () => {
    const { list, references } = this.props;
    const isLoaded = references.loaded;
    if (isLoaded) {
      const data = list.map((item) => {
        const _id = item.id;
        return {
          _id,
          ...item,
        };
      });
      return data;
    }
  };


  toggleSelection = (key, shift, row) => {
    let selection = [...this.state.selection];
    const saleFrom = [...this.state.saleFrom];
    const keyIndex = selection.indexOf(row.id);
    const selectDate = row.sale_from;
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
      this.setState((prevState) => ({
        ...prevState,
        selection,
        saleFrom,
        params: {
          ...prevState.params,
          events: keyIndex,
        },
      }));
    } else {
      selection.push(row.id);
      saleFrom.push(selectDate);
    }
    this.setState((prevState) => ({
      ...prevState,
      selection,
      saleFrom,
    }));
  };


  toggleAll = () => {
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(item._original.id);
      });
    }
    this.setState({
      selectAll,
      selection,
    });
  };

  isSelected = (key) => this.state.selection.includes(key);

  onClearSuccess = () => {
    this.props.getBasket();
    this.setState({ loading: false, selection: [] });
  };

  removeItems = (e) => {
    const { selection } = this.state;
    e.preventDefault();
    if (selection && selection.length) {
      this.setState({ loading: true });
      PromiseAll(
        selection.map((el) => (
          api.basket.removeTicket({
            basket_id: el,
          })
        )),
      ).then(this.onClearSuccess);
    }
  };

  clearBasket = (e) => {
    e.preventDefault();
    const { list } = this.props;
    if (list && list.length) {
      this.setState({ loading: true });
      PromiseAll(
        list.map((el) => (
          api.basket.removeTicket({
            basket_id: el.id,
          })
        )),
      ).then(this.onClearSuccess);
    }
  };

  showModal = () => this.setState({ open: true });

  hideModal = () => this.setState({ open: false });

  withPay = () => this.props.list.some((item) => !item['is_invitation'] || !item['is_manufacturing'] ||
    !item['is_cashless'] || !item['is_ioc']);

  handleCreatePayOrder = async (e) => {
    const { history } = this.props;
    e.preventDefault();
    const respOrderCreate = await api.orders.orderCreate();
    if (this.withPay()) {
      await api.orders.promoterOrderConfirm({ order_id: respOrderCreate.id });
    } else {
      await promoterOrderPay({
        order_id: respOrderCreate.id,
        ident_type_id: 'CASH',
        is_cash_active: false,
      });
    }
    history.push(`/basket/${respOrderCreate.id}`);
  };

  setRef = (r) => (this.checkboxTable = r);

  render() {
    const { list, references, loading } = this.props;
    const isLoaded = references.loaded && references.data['events'];
    const isHaveList = list.length;
    const columns = getColumns.call(this);
    const { toggleSelection, toggleAll, isSelected, } = this;
    const { selectAll } = this.state;
    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        const selected = r ? this.isSelected(r.original._id) : false;
        return {
          style: {
            backgroundColor: selected ? '#f4f6f7' : 'inherit',
          },
        };
      },
    };

    return (
      <Fragment>
        <div className="basket content">
          <div className="container">
            <div className="row">
              <div className="wrapper-info">
                <Title headingClass="heading" type='h2' title='Проверте свой заказ и оплатите' />
                {isLoaded ? <Fragment>
                  <div className="ku" />
                  <div className="x-scroll">
                    <div className="table-box">
                      {this.state.loadingData !== false &&
                        <CheckboxTable
                          ref={this.setRef}
                          TbodyComponent={CustomTbodyComponent}
                          SelectInputComponent={ReactTableCheckbox}
                          SelectAllInputComponent={ReactTableAllCheckbox}
                          data={this.getData()}
                          columns={columns}
                          defaultPageSize={list.length}
                          showPagination={false}
                          noDataText='Записей нет'
                          className='table-height__basket'
                          getTdProps={(state, rowInfo, column) => ({
                            key: `${rowInfo.original.id}_${column.id}`,
                          })}
                          {...checkboxProps}
                        />}
                    </div>
                  </div>
                  {isHaveList ? <div className="table-box">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <Button className={'btn btn-orange'} onClick={this.clearBasket}>Очистить корзину</Button>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <Button className={'btn'} onClick={this.removeItems}>Удалить</Button>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <Modal
                            trigger={
                              <Button
                                className='btn btn-blue'
                                disabled={!isHaveList}
                                onClick={this.showModal}
                              >
                                Оформить заказ
                              </Button>
                            }
                            closeIcon={
                              <div
                                className='close-model'
                                onClick={this.hideModal}>
                                <span />
                                <span />
                              </div>}
                            centered={true}
                            className='basket-modal'
                            dimmer='blurring'
                            open={this.state.open}
                          >
                            <Modal.Content>
                              <h2 className='content-title'>Оформление заказа</h2>
                              <p className='content-info'>
                                Вы точно хотите оформить заказ?
                              </p>
                              <Button className='btn btn-orange' onClick={this.handleCreatePayOrder}>
                                Подтвердить
                              </Button>
                              <Button className='btn btn-default' onClick={this.hideModal}>
                                Отмена
                              </Button>
                            </Modal.Content>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div> : null}
                </Fragment> : null}
              </div>
            </div>
          </div>
        </div>
        <DimmerLoader active={loading} />
      </Fragment>
    );
  }
}

const BasketListWithRefs = withReferences(BasketList);

function mapStateToProps(state) {
  return {
    ...state.basket,
  };
}

const mapDispatchToProps = {
  getBasket,
  removeTicket,
  promoterOrderPay,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketListWithRefs);
