import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { multipleDownload, parseSearch, print } from 'utils/helpers';
import { getEventCommonReport, getEventSalesReport, getSeatsListReport, clear } from 'redux/actions/reports';
import { getEvents } from 'redux/actions/events';

class FooterPanel extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    getEventCommonReport: PropTypes.func.isRequired,
    getEventSalesReport: PropTypes.func.isRequired,
    getSeatsListReport: PropTypes.func.isRequired,
    getEvents: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    clear: PropTypes.func.isRequired,
  };

  state = {
    matchId: null,
    path: this.props.path,
    pathXls: null,
    loading: false,
  };

  static getDerivedStateFromProps (props, state) {
    if (state.matchId !== props.match.params[ 'id' ]) {
      return {
        matchId: props.match.params[ 'id' ],
        pathXls: props.path,
        loading: true,
      };
    } else if (state.pathXls !== props.path) {
      return {
        pathXls: props.path,
        loading: false,
      };
    }
    return null;
  }

  onFetchDownloadLinks = () => {
    multipleDownload([ this.state.path ], 'reports');
  };

  onFetchDownloadXlsLinks = async () => {
    await this.getXLSPath();
    multipleDownload([ this.state.pathXls ], 'reports_XLS_format');
  };

  // replacePrintLinks = () => {
  //   axios.get('https://promoter.ticketpro.by/downloads.php', {
  //     headers: { 'Accept': 'application/pdf' },
  //     params: {
  //       src_url: this.state.path,
  //     },
  //   }).then(this.onFetchPrintLinks);
  // };

  onFetchPrintLinks = async (response) => {
    // const paths = response.data.src_url;
    const paths = this.state.path;
    await print(paths);
  };

  getXLSPath = () => {
    const {
      match,
      location,
      getEventCommonReport,
      getEventSalesReport,
      getSeatsListReport,
    } = this.props;
    const { type } = match.params;
    const objParams = parseSearch(location.search);
    switch (type) {
      case '1-1': case '1-2':
        return getEventCommonReport({ ...objParams, report_format: 2 });
      case '2-1': case '2-2':
        return getEventSalesReport({ ...objParams, report_format: 2 });
      case '3-1': case '3-2':
        return getSeatsListReport({ ...objParams, report_format: 2 });
      default: break;
    }
  };

  render () {
    const { loading, history } = this.props;
    return (
      <Fragment>
        <div className="form-row">
          <div className="col-lg-4 col-sm-6">
            <div className="form-group">
              <Button
                className='btn btn-orange'
                loading={loading}
                onClick={this.onFetchDownloadXlsLinks}
              >
                Сохранить XLS
              </Button>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="form-group">
              <Button
                className='btn btn-orange'
                loading={loading}
                onClick={this.onFetchPrintLinks}
              >
                Печать
              </Button>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <div className="form-group">
              <Button
                className='btn btn-orange'
                loading={loading}
                onClick={this.onFetchDownloadLinks}
              >
                Сохранить PDF
              </Button>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-6 col-sm-6">
            <div className="form-group">
              <Link to={`/reports`} className={'btn btn-blue'}> Вернуться к выбору отчета</Link>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="form-group">
              <button
                className={'btn btn-blue'}
                onClick={history.goBack}
              >Вернуться к формированию отчета</button>
            </div>
          </div>

        </div>
      </Fragment>
    );
  }
}

function mapStateToProps (state) {
  const { reports, events: { price } } = state;
  return {
    ...reports,
    price,
  };
}

const mapDispatchToProps = {
  getEventCommonReport,
  getEventSalesReport,
  getSeatsListReport,
  getEvents,
  clear,
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterPanel);
