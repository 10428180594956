import { applyMiddleware, compose, createStore } from 'redux';
import allReducers from 'redux/reducers';
import thunk from 'redux-thunk';
import { asyncActions } from 'middlewares';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(allReducers, composeEnhancers(applyMiddleware(
  thunk,
  asyncActions
)));
