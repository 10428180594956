import { ORDERS } from 'redux/types';
import api from 'server';

export const getOrderItem = (params) => ({
  type: ORDERS.ORDER_ITEM,
  promise: api.orders.order(params),
});

export const promoterOrderCancel = (params) => ({
  type: ORDERS.PROMOTER_ORDER_CANCEL,
  promise: api.orders.promoterOrderCancel(params),
});

export const promoterOrderEntityReturn = (params) => ({
  type: ORDERS.PROMOTER_ORDER_ENTITY_RETURN,
  promise: api.orders.promoterOrderEntityReturn(params),
});

export const promoterMnfOrderCancel = (params) => ({
  type: ORDERS.PROMOTER_MNF_ORDER_CANCEL,
  promise: api.orders.promoterMnfOrderCancel(params),
});

export const promoterMnfOrderEntityReturn = (params) => ({
  type: ORDERS.PROMOTER_MNF_ORDER_ENTITY_RETURN,
  promise: api.orders.promoterMnfOrderEntityReturn(params),
});

export const orderCancelPrinting = (params) => ({
  type: ORDERS.ORDER_CANCEL_PRINTING,
  promise: api.orders.orderCancelPrinting(params),
});

export const orderEntityCancelPrinting = (params) => ({
  type: ORDERS.ORDER_ENTITY_CANCEL_PRINTING,
  promise: api.orders.orderEntityCancelPrinting(params),
});
