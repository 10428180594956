import React, { Component, Fragment } from 'react';
import DimmerLoader from 'components/DimmerLoader';
import PropTypes from 'prop-types';
import getColumns from 'components/List/columns';
import TableWithPagination from 'components/TableWithPagination';

export default class List extends Component {
  static propTypes = {
    eventsList: PropTypes.array.isRequired,
    references: PropTypes.object.isRequired,
    handlePageChange: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    allCount: PropTypes.number,
    pageSize: PropTypes.number.isRequired,
  };

  render () {
    const { eventsList, references, loading, allCount, handlePageChange, pageSize } = this.props;
    const columns = getColumns.call(this);
    const isLoaded = references.loaded;
    const sortedEventsList = eventsList
      .sort((d1, d2) => new Date(d1.date_time_from).getTime() - new Date(d2.date_time_from).getTime());

    return (
      <Fragment>
        {isLoaded ? <TableWithPagination
          data={sortedEventsList}
          columns={columns}
          pageSize={pageSize}
          allCount={allCount}
          onPageChange={handlePageChange}
          manualPagination={true}
          defaultSorting={[
            {
              id: 'date_time_from',
              asc: true,
            },
          ]}
        /> : null}
        <DimmerLoader active={loading}/>
      </Fragment>
    );
  }
}
