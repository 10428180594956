import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ColumnRight extends Component{

  static propTypes = {
    children: PropTypes.any,
    style: PropTypes.object,
  };

  render () {
    const { children, style } = this.props;
    return <div className="content__right col-lg-4" style={style}>{ children }</div>;
  }
}
export default ColumnRight;
