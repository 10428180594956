import { BASKET } from 'redux/types';
import api from 'server';

const fetchBasket = async (params, dispatch) => {
  try {
    const list = await api.basket.getBasket(params);
    return list;
  } catch (e) {
    dispatch(basketError());
    throw e;
  }
};

export const getBasket = (params) => (dispatch) => {
  const promise = fetchBasket(params, dispatch);
  dispatch({
    type: BASKET.GET,
    promise,
  });
  return promise;
};

export const basketError = (error) => {
  return {
    type: BASKET.ERROR,
    error,
    errorMessage: error,
  };
};

// export const getBasket = (params) => ({
//   type: BASKET.GET,
//   promise: api.basket.getBasket(params),
// });

export const removeTicket = (params) => ({
  type: BASKET.DELETE,
  promise: api.basket.removeTicket(params),
});
