import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReportsEventList from 'components/Reports/ReportsEventList';
import CreateReport from 'components/Reports/CreateReport';
import Report from 'components/Reports/ReportType';
import ReportTypes from 'components/Reports/index';

class ReportsNew extends Component {
  render () {
    return (
      <Switch>
        <Route path='/reports/report/:id/:type' component={Report}/>
        <Route path='/reports/:type/events/:id' component={CreateReport}/>
        <Route path='/reports/:type/events' component={ReportsEventList}/>
        <Route path='/reports' component={ReportTypes}/>
      </Switch>
    );
  }
}

export default ReportsNew;
