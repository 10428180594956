import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import GeneralType from './generalType';
import StandardType from './standardType';
import PlacesType from './placesType';

class CreateReport extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  state = {
    reportVariant: null,
  };

  componentDidMount () {
    this.renderType();
  }

  renderType = () => {
    const {
      history,
      match,
      location,
    } = this.props;
    const { type } = match.params;
    const { reportVariant } = this.state;
    switch (type) {
      case '1':
        return (this.setState({
          reportVariant: <GeneralType
            history={history}
            location={location}
            match={match}/>,
        }));
      case '2':
        return (this.setState({
          reportVariant: <StandardType
            history={history}
            location={location}
            match={match}/>,
        }));
      case '3':
        return (this.setState({
          reportVariant: <PlacesType
            history={history}
            location={location}
            match={match}/>,
        }));
      default:
        return reportVariant;
    }
  };

  render () {
    const { reportVariant } = this.state;
    return (
      <Fragment>
        {reportVariant}
      </Fragment>
    );
  }
}

export default CreateReport;
