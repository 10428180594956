import React, { Component, Fragment } from 'react';
import Title from 'components/Title';
import PropTypes from 'prop-types';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { translate } from 'utils/helpers';
import Scheme from './Scheme';
import './SchemeOfPlaces.scss';

export default class SchemeOfPlaces extends Component {
  static propTypes = {
    data: PropTypes.shape({
      prices: PropTypes.array,
    }),
    references: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    svg: PropTypes.string,
    selectedPlaces: PropTypes.array,
    checkPlace: PropTypes.array,
    price: PropTypes.object,
    seatLocksList: PropTypes.array,
    onSelect: PropTypes.func,
    instanceRef: PropTypes.func,
  };

  state = {
    eventId: null,
    svg: null,
    filToCenter: true,
    open: false,
  };

  static getDerivedStateFromProps (props, state) {
    if (state.eventId !== props.data.eventId) {
      return {
        eventId: props.data.eventId,
        svg: props.svg,
        filToCenter: true,
      };
    } else if (state.svg !== props.svg) {
      return {
        svg: props.svg,
        filToCenter: false,
      };
    }
    return null;
  }

  getPlaceTypes = () => (
    this.props.data.prices.map(({ id, descriptions, color }) => (
      <div key={id} className="plan-place__type-box">
        <span className="plan-place__type-color" style={{ background: color }}/>
        {translate('ru', descriptions).name}
      </div>
    ))
  );

  getLockedPlaces = () => (
    this.props.seatLocksList.map(({ id, color, name }) =>
      <div key={id} className="plan-place__type-box">
        <span className="plan-place__type-color" style={{ background: color }}/>
        {name}
      </div>,
    )
  );

  handleSelect = (entities) => {
    const { onSelect } = this.props;
    onSelect && onSelect(entities);
  };

  handleOpen = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  render () {
    const { svg, selectedPlaces, references, match } = this.props;
    const { filToCenter, open } = this.state;
    return (
      <Fragment>
        <div className="heading-container scheme-heading">
          <Title
            type='h2'
            headingClass="heading"
            title='План зала'
          />
          <div className="counts">
            <span>Выбрано мест: </span>
            <span>
              {(selectedPlaces && selectedPlaces.length) ? <span className='counts-text'>
                {selectedPlaces.length}</span> : <span className='counts-text'>0</span>
              }
            </span>
          </div>
        </div>
        {svg && <Scheme
          svg={svg}
          references={references}
          match={match}
          onSelect={this.handleSelect}
          fitToCenter={filToCenter}
          ref={this.props.instanceRef}/>
        }
        <div className="plan-place">
          <div className="plan-place__type">
            <div className="plan-place__title" onClick={this.handleOpen}>
                 Типы резервации места
            </div>
            <div
              className={`${open ? 'plan-place__type-list' : 'plan-place__type-list close-list'}`}>
              {this.getLockedPlaces()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
