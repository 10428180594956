import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withReferences from 'hocs/withReferences';
import { getOrders, getOrderSearch } from 'redux/actions/orders';
import TableContainer from 'components/TableContainer';
import TableWithPagination from 'components/TableWithPagination';
import DimmerLoader from 'components/DimmerLoader';
import Search from './Search';
import getColumns from './columns';

class OrderList extends Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    searchResult: PropTypes.array,
    references: PropTypes.object.isRequired,
    getOrders: PropTypes.func.isRequired,
    getOrderSearch: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    isEmpty: true,
    clicked: false,
  };

  componentDidMount () {
    this.props.getOrders();
  }

  handlePageChange = () => this.props.getOrders();

  handleInputChange = (value) => {
    if (!value.length && !this.state.isEmpty) {
      this.setState({
        isEmpty: true,
      });
    }
  };

  handleButtonClick = () => this.setState({ isEmpty: false });

  getData = () => this.state.isEmpty ? this.props.list : this.props.searchResult;

  render () {
    const { list, references, loading } = this.props;
    const columns = getColumns.call(this);
    const isLoaded = references.loaded;
    return (
      <Fragment>
        <Search
          onInputChange={this.handleInputChange}
          onButtonClick={this.handleButtonClick}
          list={list}
        />
        <TableContainer title={'Ваши заказы'}>
          {isLoaded ? <TableWithPagination
            data={this.getData()}
            columns={columns}
            pageSize={10}
            onPageChange={this.handlePageChange}
            manualPagination={false}
          /> : null}
          <DimmerLoader active={loading}/>
        </TableContainer>
      </Fragment>
    );
  }
}

const ListOrdersWithRefs = withReferences(OrderList);

function mapStateToProps (state) {
  return {
    ...state.orders,
  };
}

const mapDispatchToProps = {
  getOrders,
  getOrderSearch,
};
export default connect(mapStateToProps, mapDispatchToProps)(ListOrdersWithRefs);
