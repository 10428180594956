import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Message } from 'semantic-ui-react';
import DimmerLoader from 'components/DimmerLoader';
import Field from 'components/Field';
import './LoginForm.scss';

export default class LoginForm extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
  };

  state = {
    fields: {},
  };

  onChange = (e, { name, value }) => (
    this.setState((state) => ({
      fields: { ...state.fields, [ name ]: value },
    }))
  );

  handleSubmit = (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    return onSubmit && onSubmit(this.state.fields);
  };

  render () {
    const { auth } = this.props;
    const error = auth.errorMessage;
    return (
      <div className="login-form">
        <Form onSubmit={this.handleSubmit} error={error}>
          <DimmerLoader active={auth.loading}/>
          <div className="login-form__title">Введите свой логин и пароль</div>
          <Form.Field className='form-group' error={error}>
            <Field type='input' name="login" placeholder="Логин" onChange={this.onChange}/>
          </Form.Field>
          <Form.Field className='form-group' error={error}>
            <Field type='password' name="password" placeholder="Пароль" onChange={this.onChange}/>
          </Form.Field>
          <Message error content={error}/>
          <div className="form-group">
            <button className="btn btn-orange" type='submit'>Отправить</button>
          </div>
        </Form>
      </div>
    );
  }
}
