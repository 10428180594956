import { xmlFormat } from 'constants/dateFormats';
import { getXML, get } from 'server/implementation';
import { getAuthUrl, getTerminal } from 'server/config';
import moment from 'moment';

const authUrl = getAuthUrl();
const terminal = getTerminal();

export default {
  login: (
    {
      login,
      password,
      time = moment().format(xmlFormat),
    },
  ) => getXML(authUrl,
    `<BS_Request>
        <TerminalId>${terminal}</TerminalId>
        <TerminalTime>${time}</TerminalTime>
        <RequestType>Login</RequestType>
        <Subsystem>ClientAuth</Subsystem>
        <Login Type="PWD">
          <Parameter Id="Login">${login}</Parameter>
          <Parameter Id="Password">${password}</Parameter>
        </Login>
    </BS_Request>`,
    'Login SID',
  ),
  getClientInfo: (sid) => get(`get-client-info/${sid}`, null, false),
};
