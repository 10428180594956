import axios from 'axios';
import { debounce } from 'lodash';
import { getApiUrl } from 'server/config';
import { getClientLS } from 'server/client';
import { encodeXML, parseXML, paramsSerializer } from 'utils/helpers';
import api from 'server';
import { login } from 'redux/actions/auth';
import store from 'store';

const apiUrl = getApiUrl();

const isAuthenticated = async () => {
  const client = getClientLS();
  if (client && client.sid) {
    try {
      return await api.auth.getClientInfo(client.sid);
    } catch (e) {
      return null;
    }
  }
  return null;
};

// const debounceLogin = debounce(async () => {
//   const isAuth = await isAuthenticated();
//   !isAuth && store.dispatch(login());
// }, 500);

const debounceLogin = debounce(() => store.dispatch(login()), 500);

const handleAuthError = (e) => {
  Number(e.error) === 801
    ? debounceLogin()
    : isAuthenticated().then((result) => !result && debounceLogin());
  throw e;
};

const requestWidth = (method, route, params = {}, authValidate = true) => {
  if (authValidate) {
    const client = getClientLS();

    if (!client) {
      return Promise.reject({ error: 801 });
    }
    const { sid, clientId } = client;
    return axios({
      method,
      url: `${apiUrl}/${route}/${sid}`,
      params: { ...params, client_id: clientId },
      paramsSerializer,
    })
      .catch(handleAuthError);
  } else {
    return axios({
      method,
      url: `${apiUrl}/${route}`,
      params,
      paramsSerializer,
    });
  }
};

export const get = (route, params, authValidate) =>
  requestWidth('GET', route, params, authValidate)
    .then((res) => res.data);

export const getXML = (url, xmlString, selector, errorLine = 'Error ErrorLine') => (
  axios.get(`${url}?XML=${encodeXML(xmlString)}`)
    .then((resp) => {
      const result = parseXML(resp.data, selector);
      if (result) {
        return result;
      }
      throw parseXML(resp.data, errorLine);
    })
);
