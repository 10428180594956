import { formatDateSelect } from 'constants/dateFormats';
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parseSearch } from 'utils/helpers';
import withReferences from 'hocs/withReferences';
import { getEvents, getHistoryParams, clear } from 'redux/actions/events';
import TableContainer from 'components/TableContainer';
import FilterPanel from 'components/FilterPanel/index';
import List from 'components/List/index';

const today = moment().format(formatDateSelect);

class ListEvents extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    getEvents: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    getHistoryParams: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    references: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    allCount: PropTypes.number,
    params: PropTypes.object,
  };

  componentDidMount () {
    // console.log(min, max);
    const oldParams = parseSearch(this.props.history.location.search);
    const params = { min: 1, max: 10, ...oldParams };
    if (this.props.history.location.search) {
      this.props.getHistoryParams(oldParams);
      this.props.getEvents(params);
    } else {
      this.props.getEvents({
        select_from: today,
        min: 1,
        max: 10,
      });
    }
  }

  componentWillUnmount () {
    this.props.clear();
  }

  handlePageChange = ({ min, max }) => {
    const params = { min, max, ...this.props.params };
    if (this.props.history.location.search) {
      this.props.getEvents(params);
    } else {
      this.props.getEvents({ min, max, select_from: today });
    }
  };

  render () {
    const {
      list,
      references,
      loading,
      loaded,
      allCount,
      history,
      params,
    } = this.props;

    return (
      <Fragment>
        <FilterPanel
          references={references}
          history={history}
          filterParams={params}
          showInput={true}
          showDataPicker={true}
          showSelect={true}
          loading={loading}
        />
        <TableContainer title={'Список мероприятий'}>
          <List
            pageSize={10}
            eventsList={list}
            references={references}
            loading={loading}
            loaded={loaded}
            allCount={allCount}
            handlePageChange={this.handlePageChange}
          />
        </TableContainer>
      </Fragment>
    );
  }
}

const ListEventsWithRefs = withReferences(ListEvents);

function mapStateToProps (state) {
  const { events, historyParams: { params } } = state;
  return {
    ...events,
    params,
  };
}

const mapDispatchToProps = {
  getEvents,
  getHistoryParams,
  clear,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListEventsWithRefs);
