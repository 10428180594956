import React, { Component, Fragment } from 'react';
import Title from 'components/Title';

export default class ErrorPage extends Component {
  render () {
    return (
      <Fragment>
        <div className="list">
          <div className="container">
            <div className="row">
              <div className="error-rapper">
                <Title type='h1' headingClass='heading' title='Странница на разработке'/>
                <p>
                  На данном этапе странница находится на разработке, приносим свои извинения.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
