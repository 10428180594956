import React, { Fragment } from 'react';
import Link from 'components/Link';
import PropTypes from 'prop-types';

const Login = (props) => {
  const handleLogout = (e) => {
    e.preventDefault();
    props.logout();
  };

  return (
    props.auth.client
      ? <Fragment>
        <div className="header-user">
          <div className="header-user__title">
            <span className="icon icon-user"/>
            <span className="header-user__name">{props.auth.client.fio}</span>
          </div>
        </div>
        <div className="header-logout">
          <Link text="Выйти" href="/" classNameBtn="btn" onClick={handleLogout}/>
        </div>
      </Fragment>
      : null
  );
};

Login.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    client: PropTypes.shape({
      fio: PropTypes.string,
    }),
  }).isRequired,
};

export default Login;
