import moment from 'moment';
import React, { Fragment } from 'react';

const getSeparatedTime = (string) => {
  const date = moment(string);
  return (
    <Fragment>
      <span>{date.format('DD.MM.YYYY')} </span>
      <span>{date.format('HH:mm')}</span>
    </Fragment>
  );
};

export default function () {
  return [
    {
      Header: 'Название мероприятия',
      accessor: 'descriptions[0][name]',
      width: 400,
      resizable: false,
      sortable: false,
      Cell: ({ value, original }) => (
        <Fragment>
          <p>{value} </p>
          <span> {getSeparatedTime(original.date_time_from)}</span>
        </Fragment>
      ),
    },
    {
      Header: 'Площадка / Зал',
      accessor: 'place_id',
      minWidth: 350,
      resizable: false,
      sortable: false,
      Cell: ({ value }) => <p>{this.props.references.getString('placesList', value)}</p>,
    },
    {
      Header: 'Статус / EVENT ID',
      accessor: 'event_statuses[0][name]',
      width: 180,
      resizable: false,
      sortable: false,
      Cell: ({ value, original }) => (
        <Fragment>
          <p>{value}</p>
          <p>{original.id}</p>
        </Fragment>
      ),
    },
  ];
}
