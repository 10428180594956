import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, Portal, Segment, Header } from 'semantic-ui-react';
import Title from 'components/Title';
import Field from 'components/Field';
import { Link } from 'react-router-dom';
import { List, AutoSizer } from 'react-virtualized';
import api from 'server';
import { getSeparatedTime, translate, getSeparatedPlace } from 'utils/helpers';
import DimmerLoader from 'components/DimmerLoader';

export default class PlaceSelectPanel extends Component {
  static propTypes = {
    seatLocksList: PropTypes.array.isRequired,
    selectedPlaces: PropTypes.array,
    data: PropTypes.object,
    references: PropTypes.object.isRequired,
    loaded: PropTypes.bool.isRequired,
    onLock: PropTypes.func,
    onPurchase: PropTypes.func,
    onReset: PropTypes.func,
    onRemove: PropTypes.func,
    onUnlock: PropTypes.func,
    history: PropTypes.object.isRequired,
  };

  state = {
    action: {},
    counts: {},
    groupSelected: [],
    loading: false,
    hidden: false,
    errors: false,
  };

  getOptionsFrom = (data, getText) => (
    data.map((el) => {
      const { name, color, id } = el;
      return {
        value: id,
        text: getText ? getText(el) : name,
        icon: <span className="item__type-color" style={{ background: color }}/>,
      };
    })
  );

  parsePlaceName (name) {
    const { references: { getString } } = this.props;
    return getSeparatedPlace(name)
      .map((type) => {
        const name = getString('typesObjectList', type[ 0 ]);
        return type[ 1 ] && (`${name}: ${type[ 1 ]}`);
      }).filter(Boolean);
  }

  getPrice (id) {
    const { data } = this.props;
    const priceObj = data[ 'prices' ].find((price) => String(price.id) === String(id));
    return priceObj && Number(priceObj.price);
  }

  handleInputCountChange = (element) => (e, { value }) => {
    e.preventDefault();
    value = value > 0 ? (
      Number(value) <= Number(element.maxPlaces)
        ? value
        : element.maxPlaces || 0
    ) : 0;
    this.setState((state) => ({
      counts: {
        ...state.counts,
        [ element.id ]: value,
      },
    }));
  };

  handleRemove = (id) => (e) => {
    e.preventDefault();
    const { onRemove } = this.props;
    this.setState({
      counts: {},
    });
    onRemove && onRemove(id);
  };

  rowRenderer = ({ key, index, style }) => {
    const { counts } = this.state;
    const { selectedPlaces } = this.props;
    const el = selectedPlaces[ index ];
    const placeInfo = this.parsePlaceName(el.name).slice(-2);
    const defaultInputValue = el.maxPlaces ? 1 : 0;
    return (
      <div className='booking-form__list-row' key={key} style={style}>
        <div className='--flex-between'>
          <div className='booking-form__list-name'>{placeInfo.join(', ')}</div>
          <div className='booking-form__list-field'>

            {el.isGroup ? <Field
              value={counts[ el.id ]}
              // value={counts[ el.id ] || defaultInputValue}
              defaultValue={defaultInputValue}
              type='number'
              onChange={this.handleInputCountChange(el)}
              size='mini'
            /> : null}
          </div>
          <div className='booking-form__list-price'>{this.getPrice(el.price)}</div>
        </div>
        <a
          className='booking-form__list-remove'
          href='/'
          onClick={this.handleRemove(el.id)}
        >Удалить</a>
      </div>
    );
  };

  getTotalPrice () {
    const { selectedPlaces } = this.props;
    return selectedPlaces &&
      selectedPlaces.reduce((acc, el) => acc + this.getPrice(el.price), 0);
  }

  handleActionChange = (action) => (event, { value }) => {
    this.setState({
      action: {
        [ action ]: value,
      },
    });
  };

  loader = (promise) => {
    this.setState({ loading: true });
    const end = () => this.setState({ loading: false });
    if (typeof promise === 'function') {
      promise = promise();
    }
    promise
      .then(end)
      .catch(end);
  };

  handleClickReset = (e) => {
    e.preventDefault();
    const { onReset } = this.props;
    onReset && onReset();
    this.setState({
      counts: {},
    });
  };

  getGroupSelected = async () => {
    const { selectedPlaces } = this.props;
    if (selectedPlaces && selectedPlaces.length) {
      const groupSelected = selectedPlaces.filter((el) => el.isGroup);
      const ids = groupSelected.map((el) => el.id);
      const seatInGroup = await api.places.getSeatInGroup({
        ticket_id: ids,
      });
      const groupReserved = seatInGroup.filter((el) => Boolean(el.seat_lock_id));
      return groupReserved;
    }
  };

  handleClickUnlock = async (e) => {
    e.preventDefault();
    const { selectedPlaces, onUnlock, onReset } = this.props;
    const { counts } = this.state;
    const groupSelected = selectedPlaces.filter((el) => el.isGroup);
    const reserved = selectedPlaces
      .filter((el) => Boolean(el.idReserve));
    if (groupSelected.length) {
      const ids = groupSelected.map((el) => el.id);
      const seatInGroup = await api.places.getSeatInGroup({
        ticket_id: ids,
      });
      const groupReserved = seatInGroup.filter((el) => Boolean(el.seat_lock_id));
      const groupReservedIds = groupReserved.map((el) => el.id);
      const pramsIds = (`${groupReservedIds.slice(0, `${counts[ ids ] || 1}`)}`);
      if (pramsIds !== ',' && !!pramsIds) {
        this.loader(async () => {
          await api.places.seatsUnlock(pramsIds);
          onUnlock && await onUnlock();
          this.setState({
            counts: {},
          });
        });
      } else {
        this.setState({
          errors: true,
          counts: {},
        });
        onReset && await onReset();
      }
    } else {
      if (reserved.length) {
        const ids = reserved.map((el) => el.id);
        this.loader(async () => {
          await api.places.seatsUnlock(ids);
          onUnlock && await onUnlock();
        });
        this.setState({
          counts: {},
        });
      }
    }
  };

  getSelectedIdsAndCounts = () => {
    const {
      selectedPlaces,
    } = this.props;
    const { counts } = this.state;

    if (selectedPlaces && selectedPlaces.length) {
      return selectedPlaces.map((el) => (
        `${el.id}/${counts[ el.id ] || 1}`
      ));
    }
  };

  getSelectedPrices = () => {
    const {
      selectedPlaces,
    } = this.props;

    if (selectedPlaces && selectedPlaces.length) {
      return selectedPlaces.map((el) => (
        `${el.price}`
      ));
    }
  };

  onPurchaseSuccess = async (response) => {
    const { onPurchase, history } = this.props;
    onPurchase && await onPurchase(response);
    history.push(`/basket`);
  };

  onPurchaseError = async (response) => {
    const { onPurchase } = this.props;
    onPurchase && await onPurchase(response);
    this.setState({ hidden: true });
  };

  hidePortal = () => {
    this.setState({ hidden: false });
  };

  hidePortalErrors = () => {
    this.setState({ errors: false });
  };

  handlePurchase = async (e) => {
    e.preventDefault();
    const { action } = this.state;
    const ids = this.getSelectedIdsAndCounts();
    const prices = this.getSelectedPrices();
    ids && this.loader(async () => {
      await api.places.ticketsReserve({
        tickets_and_counts: ids.join(', '),
        prices: prices.join(', '),
        is_manufacturing: !action[ 'isWithoutMoney' ],
      })
        .then(this.onPurchaseSuccess())
        .catch(this.onPurchaseError);
    });
  };

  handleWithoutMoney = async (e) => {
    e.preventDefault();
    const ids = this.getSelectedIdsAndCounts();
    ids && this.loader(async () => {
      await api.places.ticketsReserve({
        tickets_and_counts: ids.join(', '),
        prices: 1,
        is_invitation: true,
      })
        .then(this.onPurchaseSuccess())
        .catch(this.onPurchaseError);
    });
  };

  onLockSuccess = async (response) => {
    const { onLock } = this.props;
    onLock && await onLock(response);
  };

  handleLock = async (e) => {
    e.preventDefault();
    const { action } = this.state;
    const ids = this.getSelectedIdsAndCounts();
    ids && this.loader(async () => {
      await api.places.seatsLock({
        tickets_and_counts: ids.join(', '),
        seat_lock_id: action[ 'lock' ],
      });
      this.setState({
        counts: {},
        action: [],
      });
      await this.onLockSuccess();
    });
  };

  getAction () {
    const { action } = this.state;
    const { selectedPlaces } = this.props;
    if (action[ 'lock' ]) {
      return (
        <div className="form-group">
          <Button className="btn btn-orange" onClick={this.handleLock}>Заблокировать</Button>
        </div>
      );
    } else if (selectedPlaces && selectedPlaces.length) {
      return (
        <Fragment>
          <div className="form-group">
            <Button className="btn btn-orange" onClick={this.handlePurchase}>Изготовить</Button>
          </div>
          <div className="form-group">
            <Button className="btn btn-orange" onClick={this.handleWithoutMoney}>Изготовить 0,00 BYN</Button>
          </div>
        </Fragment>
      );
    }
  }

  render () {
    const { data, seatLocksList, selectedPlaces } = this.props;
    const { venue, event } = data;
    return (
      <div className="booking">
        <Title
          type='h2'
          headingClass="heading"
          title="Места и цены"
        />

        <div className="booking-form">
          <form action="#" autoComplete="off">
            <div className="booking-form__event">
              <div className="booking-form__event-title">
                {translate('ru', event.descriptions).name}
              </div>
              <div className="booking-form__event-place">
                <span className="icon icon-placeholder"/>
                {translate('ru', venue.descriptions).name}
              </div>
              <div className="booking-form__event-date">
                <span className="icon icon-calendar"/>
                {getSeparatedTime(event[ 'date_time_from' ])}
              </div>
            </div>
            <div className="booking-form__action">
              <div className="booking-form__title">Выберите действие</div>
              <div className="form-group">
                <Dropdown
                  placeholder='Блокировка мест'
                  selection
                  className='selectCustom selectCustom--color'
                  value={this.state.action[ 'lock' ]}
                  options={this.getOptionsFrom(seatLocksList)}
                  selectOnBlur={false}
                  onChange={this.handleActionChange('lock')}
                />
              </div>
            </div>
            <div className="booking-form__list">
              <div className="booking-form__list-header --flex-between">
                <div><span>Место / Ценовая категория</span></div>
                <div><span>Цена</span></div>
              </div>
              {(selectedPlaces && selectedPlaces.length)
                ? <div className="booking-form__list-body">
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        width={width}
                        height={height}
                        rowHeight={38}
                        rowCount={selectedPlaces.length}
                        rowRenderer={this.rowRenderer}
                        overscanRowCount={10}
                      />
                    )}
                  </AutoSizer>
                  {/* <PerfectScrollbar option={{ minScrollbarLength: 30 }}/> */}
                </div>
                : null}
              <div className="booking-form__list-footer --flex-between">
                <div>
                  <span>Всего мест: </span>
                  <span>{(selectedPlaces && selectedPlaces.length)
                    ? selectedPlaces.length : null}</span>
                </div>
                <div><span>{this.getTotalPrice()} BYN</span></div>
              </div>
            </div>
            <div className="booking-form__button">
              <DimmerLoader active={this.state.loading}/>
              {this.getAction()}
              <div className="form-group">
                <Portal onClose={this.hidePortal} open={this.state.hidden}>
                  <Segment>
                    <Header>При бронироварие возникла проблема</Header>
                    <p>Вы, выбрали для бронирования, два разных типа мест.</p>
                    <p>Или цена не соответствует билету.</p>
                  </Segment>
                </Portal>
              </div>
              {selectedPlaces && selectedPlaces.length ? (
                <Fragment>
                  <div className="form-group">
                    <Button
                      className="btn btn-orange"
                      onClick={this.handleClickUnlock}
                    >Разблокировать</Button>
                  </div>
                  <div className="form-group">
                    <Button
                      className="btn btn-orange"
                      onClick={this.handleClickReset}
                    >Сбросить</Button>
                  </div>
                </Fragment>
              ) : null}
              <Portal onClose={this.hidePortalErrors} open={this.state.errors}>
                <Segment>
                  <Header>При этом действии, возникла проблема</Header>
                  <p>На груповом месте, нет заблокированых мест.</p>
                </Segment>
              </Portal>
              <div className="form-group">
                <Link className="btn btn-blue" to='/'>Перейти к списку мероприятий</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
