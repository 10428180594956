import React, { Component } from 'react';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import api from 'server';
// import axios from 'axios';
import { multipleDownload, print, PromiseAll } from 'utils/helpers';
import Field from 'components/Field';
import { Link } from 'react-router-dom';
import { getEventTicketTemplateList } from 'redux/actions/events';
import DimmerLoader from 'components/DimmerLoader';

class TicketActionsPanel extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    templateList: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    templates: PropTypes.array.isRequired,
    getEventTicketTemplateList: PropTypes.func.isRequired,
    history: PropTypes.object,
    onUpgrade: PropTypes.func,
  };

  state = {
    fields: {},
    loading: false,
    errors: null,
    open: true,
    success: false,
    sendMail: false,
    loader: false,
  };

  componentDidMount () {
    const ids = this.getEventId();
    ids.map((el) => this.props.getEventTicketTemplateList({ event_id: el }));
  }

  getPdfParams = ({ id, el, needReplace, email }) => {
    const result = {
      order_id: id,
      ticket_template_type_id: el,
    };
    return email && email.length ? { ...result, email } : result;
  };

  getPdfLink = (callback, needReplace) => {
    const {
      list,
      templates,
      match,
    } = this.props;
    const { id } = match.params;
    const email = this.state.fields.email;
    if (list && list.length) {
      this.setState({ loading: true });
      PromiseAll(
        templates.map((el) => (
          api.orders.getOrderInPdf(this.getPdfParams({ id, el, needReplace, email }))
        )),
      ).then(callback);
    }
  };

  setErrorFromResponse = (response) => {
    const errors = response.reduce((acc, item) => (
      item.error ? [ ...acc, item ] : acc
    ), []);
    this.setState({ errors });
  };

  hideModal = () => {
    this.setState({ open: false });
  };

  successRender = () => {
    const { open } = this.state;
    return (
      <Modal
        closeIcon={
          <div
            className='close-model'
            onClick={this.hideModal}>
            <span/>
            <span/>
          </div>}
        centered={true}
        className='basket-modal'
        dimmer='blurring'
        open={open}
      >
        <Modal.Content>
          <h2 className='content-title'>Поздравляем!</h2>
          <p className='content-info'>
            Ваше действие над билетами, прошло успешно!
            Перейти на главную страницу?
          </p>
          <Link className='btn btn-orange' to='/'>Перейти</Link>
          <Button className='btn btn-default' onClick={this.hideModal}>
            Отмена
          </Button>
        </Modal.Content>
      </Modal>
    );
  };

  successMailSend = () => {
    const { open, fields: { email } } = this.state;
    return (
      <Modal
        closeIcon={
          <div
            className='close-model'
            onClick={this.hideModal}>
            <span/>
            <span/>
          </div>}
        centered={true}
        className='basket-modal'
        dimmer='blurring'
        open={open}
      >
        <Modal.Content>
          <h2 className='content-title'>Поздравляем!</h2>
          <p className='content-info'>
            Письмо было отправлено на почту
            <b>{ email }</b>
          </p>
          <Link className='btn btn-orange' to='/'>Перейти на главную</Link>
          <Button className='btn btn-default' onClick={this.hideModal}>
            Отмена
          </Button>
        </Modal.Content>
      </Modal>
    );
  };

  onFetchReplaceLinks = async (response) => {
    const { onUpgrade } = this.props;

    const paths = response.map((item) => item);
    await print(paths);
    onUpgrade && await onUpgrade(response);
    this.setState({
      loading: false,
      errors: null,
      success: true,
      loader: false,
    });
  };

  // replacePrintLinks = (paths) => {
  //   PromiseAll(
  //     paths.map((el) => (
  //       axios.get('https://promoter.ticketpro.by/downloads.php', {
  //         headers: { 'Accept': 'application/pdf' },
  //         params: {
  //           src_url: el,
  //         },
  //       })
  //     )),
  //   ).then(this.onFetchReplaceLinks);
  // };

  onFetchPrintLinks = async (response) => {
    await this.setErrorFromResponse(response);
    const paths = response.map((el) => el.path);
    await this.onFetchReplaceLinks(paths);
  };

  onFetchDownloadLinks = async (response) => {
    const { onUpgrade } = this.props;
    this.setErrorFromResponse(response);
    const paths = response.map((el) => el.path);
    await multipleDownload(paths, 'tickets');
    onUpgrade && await onUpgrade(response);
    this.setState({
      loading: false,
      errors: null,
      success: true,
      loader: false,
    });
  };

  onFetchEmailSuccess = async (response) => {
    this.setErrorFromResponse(response);
    const { onUpgrade } = this.props;
    onUpgrade && await onUpgrade(response);
    this.setState({
      loading: false,
      errors: null,
      success: false,
      sendMail: true,
      loader: false,
    });
  };

  onChange = (e, { name, value }) => (
    this.setState((state) => ({
      fields: { ...state.fields, [ name ]: value },
    }))
  );

  loader = (promise) => {
    this.setState({ loading: true });
    const end = () => this.setState({ loading: false });
    if (typeof promise === 'function') {
      promise = promise();
    }
    promise
      .then(end)
      .catch(end);
  };

  getSelectedIdsAndCounts = () => {
    const {
      list,
    } = this.props;

    if (list && list.length) {
      return list.map((el) => (
        `${el.id}`
      ));
    }
  };

  getEventId = () => {
    const {
      list,
    } = this.props;
    if (list && list.length) {
      return list.reduce((acc, item) => {
        acc.indexOf(item[ 'event_id' ]) === -1 && acc.push(item[ 'event_id' ]);
        return acc;
      }, []);
    }
  };

  setTemplate = async (typeId) => {
    const { templateList, onUpgrade } = this.props;
    const ids = this.getSelectedIdsAndCounts();
    const templateId = templateList.filter((el) => typeId === el.type[ 'id' ]).map((el) => el.id).reduce((acc) => acc);
    ids && this.loader(async () => {
      await api.orders.setTicketTemplate({
        order_entities: ids.join(', '),
        ticket_template_id: templateId,
      }).then();
      onUpgrade && await onUpgrade();
    });
  };

  handlePrint = async (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    // await this.setTemplate(3);
    setTimeout(() => {
      this.getPdfLink(this.onFetchPrintLinks, false);
    }, 1500);
  };

  handleDownload = async (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    await this.setTemplate(1);
    setTimeout(() => {
      this.getPdfLink(this.onFetchDownloadLinks, true);
    }, 2000);
  };

  handleSendEmail = async (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    await this.setTemplate(1);
    setTimeout(() => {
      this.getPdfLink(this.onFetchEmailSuccess, true);
    }, 2000);
  };

  getTicketsStatusId = () => {
    const statusId = 'status_id';
    return this.props.list.reduce((acc, item) => {
      acc.indexOf(item[ statusId ]) === -1 && acc.push(item[ statusId ]);
      return acc;
    }, []);
  };

  render () {
    const { loading } = this.props;
    const { errors, success, sendMail, loader } = this.state;
    const statusId = this.getTicketsStatusId();
    const disable = statusId[ 0 ] === 3 || statusId[ 0 ] === -3;
    return (
      <div className="booking">
        <div className="booking-form">
          <DimmerLoader active={loader}/>
          <div className="booking-form__button">
            <div className="form-group">
              <Button
                className="btn btn-orange"
                onClick={this.handlePrint}
                loading={loading}
                disabled={disable}
              >Печать</Button>
            </div>
            <div className="form-group">
              <Button
                className="btn btn-orange"
                onClick={this.handleDownload}
                loading={loading}
                disabled={disable}
              >Сохранить на компьютер</Button>
            </div>
          </div>
          <Form onSubmit={this.handleSendEmail}>
            <div className='booking-form__action'>
              <div className='booking-form__title'>Отправка билетов на email</div>
              <Form.Field className='form-group'>
                <Field type='input' name="email" placeholder="Введите email" onChange={this.onChange}/>
              </Form.Field>
            </div>
            <div className='booking-form__send'>
              <div className='form-group'>
                <Button
                  className='btn btn-orange'
                  loading={loading}
                  disabled={disable}
                >Отправить</Button>
              </div>
            </div>
          </Form>
          {errors && errors.length ? <Message negative>
            <b>Внимание!</b>
            <p>Данной ценовой категории не присвоен необходимый шаблон билета.
              Обратитесь к администратору для устранения данной проблемы!</p>
          </Message> : null}
          {success ? this.successRender() : null}
          {sendMail ? this.successMailSend() : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    ...state.ticketTemplateList,
  };
}

const mapDispatchToProps = {
  getEventTicketTemplateList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketActionsPanel);
