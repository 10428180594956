import { get } from 'server/implementation';

export default {
  /**
   * @param {string} client_id  client's id from get_client_info
   */
  getBasket: (params) => get('get-basket', params),
  /**
   * @param {string} client_id  client's id from get_client_info
   * @param {string} basket_id  Cart id record
   */
  removeTicket: (params) => get('ticket-delete', params),
};
