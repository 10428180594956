import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from 'utils/helpers';

const ReferencesContext = React.createContext();

const parseData = (data, lang) => (
  data ? _.mapValues((data), (ref) => (
    ref && ref.map((item) => {
      const descriptions = item.descriptions || item.names;
      return descriptions
        ? {
          ...item,
          descriptions: translate(lang, descriptions),
        }
        : item;
    })
  )) : null
);

class CustomProvider extends Component {
  static propTypes = {
    getReferences: PropTypes.func.isRequired,
    getStartReferences: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    children: PropTypes.any,
  };

  state = {
    data: null,
    lang: null,
  };

  static getDerivedStateFromProps (props, state) {
    if (props.lang !== state.lang || props.data !== state.data) {
      return {
        data: parseData(props.data, props.lang),
        lang: props.lang,
      };
    }
    return null;
  }

  componentDidMount () {
    this.props.getStartReferences();
  }

  // @param {string} ref Reference name
  // @param {string|number} id
  getFromRefs = (ref, id) => {
    const { data } = this.state;
    const target = _.get(data, ref);

    if (target && !_.isNil(id)) {
      return target.find((item) => String(item.id) === String(id));
    }
    return target;
  };

  // @param {string} ref Reference name
  // @param {string|number} id
  getStringFromRefs = (ref, id) => {
    const target = this.getFromRefs(ref, id);
    return target && _.get(target, 'descriptions.name');
  };

  getContextValue () {
    const { data } = this.state;
    const { loading, getReferences } = this.props;
    return {
      references: {
        data,
        get: this.getFromRefs,
        getString: this.getStringFromRefs,
        loaded: Boolean(data),
        loading: loading,
        load: getReferences,
      },
    };
  }

  render () {
    return (
      <ReferencesContext.Provider value={this.getContextValue()}>
        {this.props.children || []}
      </ReferencesContext.Provider>
    );
  }
}

export const ReferencesProvider = CustomProvider;
export default (Component) => function withReferences (props) {
  return (
    <ReferencesContext.Consumer>
      {(value) => <Component {...props} {...value}/>}
    </ReferencesContext.Consumer>
  );
};
