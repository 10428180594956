import React from 'react';
import { getSeparatedTime, getSeparatedPlace } from 'utils/helpers';

export default function () {
  return [
    {
      Header: 'Номер билета',
      accessor: 'ticket_id',
      Cell: ({ value }) => <div>{value}</div>,
      minWidth: 200,
      resizable: false,
      id: `ticket_id`,
    },
    {
      Header: 'Дата/Время',
      accessor: 'date_time_from',
      minWidth: 230,
      resizable: false,
      Cell: ({ value }) => <p className='data-time'>{getSeparatedTime(value)}</p>,
    },
    {
      Header: 'Стоимость',
      accessor: 'price',
      minWidth: 150,
      resizable: false,

    },
    {
      Header: 'Место',
      accessor: 'ticket_name',
      minWidth: 175,
      resizable: false,
      Cell: ({ value }) => {
        const { references } = this.props;
        return (
          getSeparatedPlace(value).map((el) =>
            <p key={`${el[ 0 ]}_${el[ 1 ]}`}>
              <span>{references.getString('typesObjectList', el[ 0 ])}: </span>
              {el[ 1 ]}
            </p>,
          )
        );
      },
    },
    {
      Header: 'Статус',
      accessor: 'status[0][name]',
      minWidth: 160,
      resizable: false,
    },
  ];
}
