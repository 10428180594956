import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import PDF from 'react-pdf-js-infinite';
import { connect } from 'react-redux';
import DimmerLoader from 'components/DimmerLoader';
import Title from 'components/Title';
import FooterPanel from 'components/Reports/footerPanel';
import { getEventCommonReport, getEventSalesReport, getSeatsListReport, clear } from 'redux/actions/reports';
import { getEvents } from 'redux/actions/events';
import { parseSearch } from 'utils/helpers';

const TITLES = {
  '1-1': 'Отчет по мероприятиям - общий (полный)',
  '1-2': 'Отчет по мероприятиям - общий (Период времени)',
  '2-1': 'Отчет по мероприятиям - стандартный (полный)',
  '2-2': 'Отчет по мероприятиям - стандартный (Период времени)',
  '3-1': 'Статистика по местам',
  '3-2': 'Статистика по местам',
};

class ReportType extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getEventCommonReport: PropTypes.func.isRequired,
    getEventSalesReport: PropTypes.func.isRequired,
    getSeatsListReport: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    getEvents: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    price: PropTypes.array,
    errorMessage: PropTypes.string,
  };

  state = {
    matchId: null,
    list: null,
    title: 'Отчет',
    path: null,
    loading: false,
  };

  static getDerivedStateFromProps (props, state) {
    if (state.matchId !== props.match.params[ 'id' ]) {
      return {
        matchId: props.match.params[ 'id' ],
        path: props.path,
        loading: true,
      };
    } else if (state.path !== props.path) {
      return {
        path: props.path,
        loading: false,
      };
    }
    return null;
  }

  async componentDidMount () {
    const { match, getEvents } = this.props;
    const { id } = match.params;
    await getEvents({ events: id });
    await this.getDataByQuery();
    this.setTitle();
  }

  componentWillUnmount () {
    const { clear } = this.props;
    clear();
  }

  getDataByQuery = () => {
    const {
      match,
      location,
      getEventCommonReport,
      getEventSalesReport,
      getSeatsListReport,
    } = this.props;
    const { type } = match.params;
    const objParams = parseSearch(location.search);
    switch (type) {
      case '1-1': case '1-2':
        return getEventCommonReport(objParams);
      case '2-1': case '2-2':
        return getEventSalesReport(objParams);
      case '3-1': case '3-2':
        return getSeatsListReport(objParams);
      default: break;
    }
  };

  setTitle = () => {
    const {
      match: { params },
    } = this.props;
    const { type } = params;
    const { title } = this.state;
    this.setState({ title: TITLES[ type ] || title });
  };

  render () {
    const { path, loading, match, errorMessage, location, history, list } = this.props;
    const isLoaded = path.length;
    return (
      <div className="report-jeneral">
        <div className="container">
          {isLoaded ? <Fragment>
            <Title type='h1' headingClass='heading' title={this.state.title}/>
            <div className='wrapper-reports'>
              <a
                href={this.state.path}
              >
                <PDF
                  file={this.state.path}
                  scale={1.5}
                />
              </a>
            </div>
            <FooterPanel
              match={match}
              path={this.state.path}
              list={list}
              loading={loading}
              location={location}
              history={history}
            />
          </Fragment> : <p>{errorMessage}</p>}
          <DimmerLoader active={loading}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  const { reports, events: { list, price } } = state;
  return {
    ...reports,
    list,
    price,
  };
}

const mapDispatchToProps = {
  getEventCommonReport,
  getEventSalesReport,
  getSeatsListReport,
  getEvents,
  clear,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportType);
