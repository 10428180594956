import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ReferencesProvider } from 'hocs/withReferences';
import Authenticated from 'containers/Authenticated';

// Actions
import { getStartReferences, getReferences } from 'redux/actions/references';
import { setDictionary } from 'redux/actions/dictionary';

// Route
import Header from 'components/Header';
import Footer from 'components/Footer';
import ListEvents from 'views/ListEvents';
import Basket from 'views/Basket';
import PlaceSelect from 'views/PlaceSelect';
import Orders from 'views/Orders';
import Reports from 'views/ReportsNew';
// import ReportTypes from 'views/Reports/ReportTypes';
// import ReportGeneral from 'views/Reports/ReportGeneral';
// import ReportStandard from 'views/Reports/ReportStandard';
// import ReportOnPlaces from 'views/Reports/ReportOnPlaces';
import ErrorPage from 'views/ErrorPage';

// Style
import 'react-table/react-table.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'style/css/libs.css';
import 'style/css/style.css';
import 'style/componentsStyle.scss';

const lang = 'ru';

class App extends Component {
  static propTypes = {
    references: PropTypes.object.isRequired,
    getReferences: PropTypes.func.isRequired,
    getStartReferences: PropTypes.func.isRequired,
    setDictionary: PropTypes.func.isRequired,
  };

  componentDidMount () {
    this.props.setDictionary(lang);
  }

  render () {
    return (
      <Router>
        <div className="wrapper">
          <Header/>
          <Authenticated>
            <main className="main">
              <ReferencesProvider
                lang={lang}
                getReferences={this.props.getReferences}
                getStartReferences={this.props.getStartReferences}
                {...this.props.references}
              >
                <Switch>
                  <Route exact path='/' component={ListEvents}/>
                  <Route path='/basket' component={Basket}/>
                  <Route path='/events/:eventId' component={PlaceSelect}/>
                  <Route path='/reports' component={Reports}/>
                  <Route path='/orders' component={Orders}/>
                  <Route path='/settings' component={ErrorPage}/>
                </Switch>
              </ReferencesProvider>
            </main>
            <Footer/>
          </Authenticated>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = ({ references }) => ({
  references,
});

const mapDispatchToProps = {
  setDictionary,
  getStartReferences,
  getReferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
