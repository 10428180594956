import { COMMON } from 'redux/types';

export const asyncActions = (store) => (next) => (action) => {
  if (action.promise) {
    const { promise, ...rest } = action;
    store.dispatch({
      ...rest,
      type: action.type + COMMON.START,
    });
    return promise
      .then((response) => {
        store.dispatch({
          ...rest,
          type: action.type + COMMON.SUCCESS,
          response,
        });
      })
      .catch((e) => {
        // console.log(e);
        // console.log(response);
        store.dispatch({
          ...rest,
          type: action.type + COMMON.ERROR,
          error: e.error,
          // errorMessage: response.response.data.text,
        });
      });
  // .catch((response) => {
  //     store.dispatch({
  //       ...rest,
  //       type: action.type + COMMON.ERROR,
  //       errorMessage: response.response.data.text,
  //       error: response.response.data,
  //     });
  //   });
  } else {
    return next(action);
  }
};
