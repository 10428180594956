import React, { Component } from 'react';
import logo from 'img/logo.svg';
import Menu from 'components/Menu';
import Login from 'containers/Login';

const localeOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  weekday: 'long',
  timezone: 'UTC',
};

const getHeaderTime = () => {
  const today = new Date();
  return (
    today.toLocaleDateString('ru-Ru', localeOptions) +
    ' / ' +
    ('0' + today.getHours()).slice(-2) +
    ':' +
    ('0' + today.getMinutes()).slice(-2)
  );
};

class Header extends Component {
  state = {
    date: getHeaderTime(),
    height: 0,
    scrollTop: 0,
    active: false,
  };

  componentWillMount () {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrollTop = window.scrollY;

    this.setState({
      scrollTop: scrollTop,
    });
  };

  toggleClass = () => {
    this.setState((currentState) => ({
      active: !currentState.active,
    }));
  };

  render () {
    const { scrollTop, height } = this.state;
    return (
      <header className={scrollTop > height ? 'header fixed' : 'header'}>
        <div className="header-inner">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-2 col-sm-3 col-3">
                <div className="logo">
                  <a href="/">
                    <img src={logo} alt="logo"/>
                  </a>
                </div>
              </div>
              <div className="col-lg-5 d-none d-lg-block">
                <div className="header-date">{this.state.date}</div>
              </div>
              <div className="col-lg-5 col-sm-9 col-9">
                <div className="header-right">
                  <div className="header-lang">
                    <div className="header-lang__title">
                      <span className="header-lang__name">Ru</span>
                      <span className="icon icon-angle-down"></span>
                    </div>
                    <div className="header-lang__modal">
                      <ul>
                        <li><a href="https://reactjs.org">Ru</a></li>
                        <li><a href="https://reactjs.org">En</a></li>
                      </ul>
                    </div>
                  </div>
                  <Login/>
                  <div
                    className={this.state.active ? 'menu-toggle js-menu-toggle active' : 'menu-toggle js-menu-toggle'}
                    onClick={this.toggleClass}
                    data-toggle="top-menu">
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Menu active={this.state.active} close={() => this.toggleClass()}/>
      </header>
    );
  }
}

export default Header;
