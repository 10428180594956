import { COMMON, ORDERS } from 'redux/types';

const defaultState = {
  list: {},
  loading: false,
  loaded: false,
};

export default function orderList (state = defaultState, action = {}) {
  switch (action.type) {
    case ORDERS.ORDER_CREATE + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }

    case ORDERS.ORDER_CREATE + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    }

    case ORDERS.ORDER_CREATE + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.response,
      };
    }
    case ORDERS.PROMOTER_ORDER_PAY + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }

    case ORDERS.PROMOTER_ORDER_PAY + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case ORDERS.PROMOTER_ORDER_PAY + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        list: action.response,
      };
    }

    default:
      return state;
  }
}
