import { get } from 'server/implementation';
export default {
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {string($length=300)} event_name  Events/Title (or part of title)
   * (cannot be read if event_id or events is passed)
   * @param {integer} event_category_id  Events / Category (not readable if event_id or events is passed)
   * @param {string($YYYY-MM-DD hh24:mi:ss)} event_date_from  Events / Start date / from
   * (cannot be read if event_id or events is passed)
   * @param {string($YYYY-MM-DD hh24:mi:ss)} event_date_to  vents / Start date / to
   * (cannot be read if event_id or events is passed)
   * @param {string($integer:id1,id2,...,idN)} events  List of Event IDs
   * @param {string($YYYY-MM-DD hh24:mi:ss)} sale_from  Date of sale / From
   * @param {string($YYYY-MM-DD hh24:mi:ss)} sale_to   Date of sale / On
   * @param {integer} report_format   Report format (1 - txt; 2 - xls; 4 - rtf; 8 - pdf)/Default value: 2
   */
  getEventCommonReport: (params) => get('promoter-common-event-report', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} event_id  Event (Identifier)
   * @param {integer} venue_id  Events / Venue (cannot be read if event_id or events is passed)
   * @param {string($length=300)} event_name  Events/Title (or part of title)
   * (cannot be read if event_id or events is passed)
   * @param {string($length=20)} event_code Events / Code (cannot be read if event_id or events is passed)
   * @param {string($length=20)} event_category_id Events / Category (not readable if event_id or events is passed)
   * @param {string($YYYY-MM-DD hh24:mi:ss)} event_date_from  Events / Start date / from
   * (cannot be read if event_id or events is passed)
   * @param {string($YYYY-MM-DD hh24:mi:ss)} event_date_to  Events / Start date / to
   * (cannot be read if event_id or events is passed)
   * @param {boolean} show_sold_by_ticketpro  Ticketpro/Sales?/Default value:false
   * @param {boolean} show_manufactured_by_ticketpro  Ticketpro/Make with the price?/Default value:false
   * @param {boolean} show_invitations_by_ticketpro  Ticketpro/tickets with the price of 0.00 rubles.?
   * Default value:false
   * @param {boolean} show_sold_by_promoter  Organizer tickets/Sales?/Default value:false
   * @param {boolean} show_manufactured_by_promoter  Organizer tickets/Make with the price?/Default value:false
   * @param {boolean} show_invitations_by_promoter  Organizer tickets/with the price of 0.00 rubles.?
   * Default value:false
   * @param {string($YYYY-MM-DD hh24:mi:ss)} sale_from  Date of sale / From
   * @param {string($YYYY-MM-DD hh24:mi:ss)} sale_to  Date of sale / On
   * @param {integer} report_format  Report format (1 - txt; 2 - xls; 4 - rtf; 8 - pdf)/Default value: 2
   */
  getEventSalesReport: (params) => get('promoter-event-report', params),
  /**
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} event_id  Event (Identifier)
   * @param {integer} price  Price Category (ID)
   * @param {boolean} show_sold_by_ticketpro  Ticketpro/Sales?/Default value:false
   * @param {boolean} show_manufactured_by_ticketpro  Ticketpro/Make with the price?/Default value:false
   * @param {boolean} show_invitations_by_ticketpro  Ticketpro/tickets with the price of 0.00 rubles.?
   * Default value:false
   * @param {boolean} show_sold_by_promoter  Organizer tickets/Sales?/Default value:false
   * @param {boolean} show_manufactured_by_promoter  Organizer tickets/Make with the price?/Default value:false
   * @param {boolean} show_invitations_by_promoter  Organizer tickets/with the price of 0.00 rubles.?
   * Default value:false
   * @param {string($YYYY-MM-DD hh24:mi:ss)} sale_from  Date of sale / From
   * @param {string($YYYY-MM-DD hh24:mi:ss)} sale_to  Date of sale / On
   * @param {integer} report_format  Report format (1 - txt; 2 - xls; 4 - rtf; 8 - pdf)/Default value: 2
   */
  getSeatsListReport: (params) => get('promoter-seats-list-report', params),
};
