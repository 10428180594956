import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'semantic-ui-react';
import ReactTable from 'react-table';

export default class TableWithPagination extends Component {
  static propTypes = {
    manualPagination: PropTypes.bool,
    allCount: PropTypes.number,
    columns: PropTypes.array.isRequired,
    onPageChange: PropTypes.func,
    pageSize: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
  };

  static defaultProps = {
    manualPagination: false,
  };

  state = {
    activePage: 1,
    min: 1,
    max: null,
    startIndex: 1,
    oldData: 0,
  };

  static getDerivedStateFromProps (props, state) {
    if (props.data !== state.oldData) {
      return {
        oldData: props.data,
        startIndex: state.min,
      };
    }
    return null;
  }

  getTotalPages = () => {
    const { pageSize, data, manualPagination, allCount } = this.props;
    const length = manualPagination ? allCount : data.length;
    return Math.ceil(length / pageSize);
  };

  handlePageChange = (e, { activePage }) => {
    const { onPageChange } = this.props;
    const range = this.getRangeEntities(activePage);
    const result = { activePage, ...range };
    this.setState(result);
    onPageChange && onPageChange(result);
  };

  getRangeEntities = (activePage) => {
    const { manualPagination, pageSize } = this.props;
    return manualPagination ? {
      min: pageSize * (activePage - 1) + 1,
      max: pageSize * activePage,
    } : {};
  };

  getIndexCol = () => {
    const { manualPagination } = this.props;
    const { startIndex } = this.state;
    return {
      Header: '#',
      id: 'row',
      Cell: (row) => {
        let index = row.index;
        if (manualPagination) {
          index += startIndex;
        } else {
          index += startIndex;
        }
        return <div>{index}</div>;
      },
      width: 70,
      resizable: false,
      sortable: false,
    };
  };

  getColumns = () => {
    const { columns } = this.props;
    const index = this.getIndexCol();
    return [ index, ...columns ];
  };

  render () {
    const { activePage } = this.state;
    const { manualPagination } = this.props;
    return (
      <Fragment>
        <div className="table-box">
          <div className="table-responsive">
            <ReactTable
              showPagination={false}
              defaultPageSize={5}
              noDataText='Записей нет'
              {...this.props}
              page={manualPagination ? null : activePage - 1}
              columns={this.getColumns()}
            />
          </div>
        </div>
        <Pagination
          totalPages={this.getTotalPages()}
          activePage={activePage}
          onPageChange={this.handlePageChange}
          nextItem={'Следующая'}
          prevItem={'Предыдущая'}
          // ellipsisItem={null}
          firstItem={null}
          lastItem={null}
        />
      </Fragment>
    );
  }
}
