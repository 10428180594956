import { EVENTS, COMMON } from 'redux/types';

const defaultState = {
  list: [],
  loading: false,
  loaded: false,
  price: [],
};

export default function events (state = defaultState, action = {}) {
  switch (action.type) {
    case EVENTS.GET + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }
    case EVENTS.GET + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    }
    case EVENTS.GET + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.response[ 'events' ],
        allCount: action.response[ 'all_count' ],
      };
    }
    case EVENTS.GET_PRICE_LIST + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }
    case EVENTS.GET_PRICE_LIST + COMMON.ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case EVENTS.GET_PRICE_LIST + COMMON.SUCCESS: {
      return {
        ...state,
        price: action.response,
      };
    }
    case EVENTS.CLEAR: {
      return {
        ...state,
        loaded: false,
        list: [],
      };
    }
    default:
      return state;
  }
}
