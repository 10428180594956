import { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

export default class SvgLoader extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    children: PropTypes.func,
    onLoad: PropTypes.func,
  };

  state = {
    loading: false,
    loaded: false,
    svg: null,
  };

  componentDidMount () {
    this.load();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.path !== this.props.path && this.state.loading === false) {
      return this.load();
    }
  }

  handleLoad (svg) {
    const { onLoad } = this.props;
    this.setState({
      loaded: true,
      loading: false,
      svg,
    });
    onLoad && onLoad(svg);
  }

  load = () => {
    if (this.props.path) {
      this.setState({ loading: true });
      axios(this.props.path)
        .then(({ data }) => this.handleLoad(data));
    }
  };

  render () {
    const { children } = this.props;
    if (typeof children === 'function') {
      return children(this.state);
    }
  }
}
