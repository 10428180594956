import React, { Component, Fragment } from 'react';
import TicketActionsPanel from 'components/RightSide/TicketActionsPanel';
import ColumnLeft from 'components/ColumnLeft';
import ColumnRight from 'components/ColumnRight';
import Title from 'components/Title';
import { connect } from 'react-redux';
import withReferences from 'hocs/withReferences';
import PropTypes from 'prop-types';
import DimmerLoader from 'components/DimmerLoader';
import { getOrderItem } from 'redux/actions/orderTickets';
import { getEventTicketTemplateList } from 'redux/actions/events';
import ReactTable, { ReactTableDefaults } from 'react-table';
import PerfectScrollbar from 'react-perfect-scrollbar';
import getColumns from './columns';

const CustomTbodyComponent = (props) => (
  <ReactTableDefaults.TbodyComponent {...props}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </ReactTableDefaults.TbodyComponent>
);

class TicketActions extends Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    references: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    getOrderItem: PropTypes.func.isRequired,
    getEventTicketTemplateList: PropTypes.func.isRequired,
  };

  state = {
    templates: [ 3 ],
  };

  async componentDidMount () {
    const { id } = this.props.match.params;
    await this.props.getOrderItem({ order_id: id });
    await this.setState({
      templates: this.getOrderTemplates(),
    });
  }

  getOrderTemplates = () => {
    const templateType = 'ticket_template_type_id';
    return this.props.list.reduce((acc, item) => {
      acc.indexOf(item[ templateType ]) === -1 && acc.push(item[ templateType ]);
      return acc;
    }, []);
  };

  listUpdate = async () => {
    const { id } = this.props.match.params;
    await this.props.getOrderItem({ order_id: id });
    await this.setState({
      templates: this.getOrderTemplates(),
    });
  };

  render () {
    const { list, references, loading, match } = this.props;
    const columns = getColumns.call(this);
    const isLoaded = list.length && references.loaded;
    return (
      <Fragment>
        <div className="content">
          <div className="container">
            <div className="row">
              <ColumnLeft>
                <Title headingClass="heading" type='h2' title='Ваши билеты'/>
                {isLoaded ? <div className="tickets">
                  <ReactTable
                    noDataText='Записей нет'
                    TbodyComponent={CustomTbodyComponent}
                    data={list}
                    columns={columns}
                    defaultPageSize={list.length}
                    showPagination={false}
                    className='table-height'
                    loading={loading}
                  />
                </div> : null}
                <DimmerLoader active={loading}/>
              </ColumnLeft>
              <ColumnRight>
                {isLoaded ? <TicketActionsPanel
                  list={list}
                  match={match}
                  loading={loading}
                  templates={this.state.templates}
                  onUpgrade={this.listUpdate}
                /> : null}
                <DimmerLoader active={loading}/>
              </ColumnRight>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const TicketActionsWithRefs = withReferences(TicketActions);

function mapStateToProps (state) {
  return {
    ...state.orderTickets,
  };
}

const mapDispatchToProps = {
  getOrderItem,
  getEventTicketTemplateList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketActionsWithRefs);
