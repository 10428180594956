import { formatDateSelect } from 'constants/dateFormats';
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Radio, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { getEventPricesList } from 'redux/actions/events';
import Title from 'components/Title';
import { paramsSerializer, parseSearch } from 'utils/helpers';
import { ticketProTickets, organizerTickets } from './initialData';

class StandardType extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getEventPricesList: PropTypes.func.isRequired,
    price: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
  };

  state = {
    isToggleOn: true,
    startDate: null,
    endDate: null,
    title: '',
    fields: {
      'report-set': '2-1',
    },
    selection: [],
  };

  collectData = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [ name ]: value,
      },
    }));
  };

  handleChangeStart (date) {
    this.setState({
      startDate: date,
    });
    this.collectData('date_time_from', date.startOf('day').format(formatDateSelect));
  }

  handleChangeEnd (date) {
    this.setState({
      endDate: date,
    });
    this.collectData('date_time_to', date.endOf('day').format(formatDateSelect));
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { fields } = this.state;
    if (name === 'report-type' && fields[ 'report-set' ] && fields[ 'report-set' ].length) {
      this.setState({
        fields: {
          [ name ]: value,
        },
      });
    } else {
      this.setState({
        fields: {
          ...fields,
          [ name ]: value,
        },
      });
    }
  };

  handleChangeCheckbox = (event) => {
    const { name, checked } = event.target;
    const { fields } = this.state;
    const selectedTicketPro = ticketProTickets.map((item) => (item.name));
    const selectedOrganizer = organizerTickets.map((item) => (item.name));
    if (name === fields[ `${selectedTicketPro}` ] && fields[ `${selectedOrganizer}` ] && fields[ 'report-set' ] && fields[ 'report-set' ].length) {
      this.setState({
        fields: {
          [ name ]: checked || false,
        },
      });
    } else {
      this.setState({
        fields: {
          ...fields,
          [ name ]: checked || false,
        },
      });
    }
  };

  getLocation = () => {
    const { fields } = this.state;
    const { history, match, location } = this.props;
    const { id } = match.params;
    const typeReport = fields[ 'report-set' ];
    const objParams = parseSearch(location.search);
    const events = location.state;
    const reportParams = paramsSerializer({
      events: events,
      sale_from: fields[ 'report-set' ] === '2-2' ? fields[ 'date_time_from' ] : objParams.sale_from,
      sale_to: fields[ 'report-set' ] === '2-2' ? fields[ 'date_time_to' ] : objParams.sale_to,
      report_format: 8,
    });
    history.push({
      pathname: `/reports/report/${id}/${typeReport}`,
      search: reportParams,
    });
  };

  render () {
    const {
      startDate,
      endDate,
      fields,
    } = this.state;
    return (
      <div className="report-type">
        <div className="container">
          <Title type='h1' headingClass='heading' title='Отчет по мероприятиям - стандартный'/>
          <div className="table-box">
            <Form autoComplete="off">
              <Form.Group grouped>
                <div className="radio-elem">
                  <Form.Field className="checkbox">
                    <Radio
                      id='2-1'
                      name='report-set'
                      value='2-1'
                      checked={fields[ 'report-set' ] === '2-1'}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <div className="radio-text">Полный<br/>
                    <span>Отчет с начала продаж</span>
                  </div>
                </div>
                <div className="radio-elem">
                  <Form.Field className="checkbox">
                    <Radio
                      id='2-2'
                      value='2-2'
                      name='report-set'
                      checked={fields[ 'report-set' ] === '2-2'}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <div className="radio-text">Период времени<br/>
                    <span>
                        Для отчета за определенный период необходимо выбрать
                        <b> Дата с</b>  и <b>Дата до</b>
                      </span>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-lg-12">
                    <div className={classNames('input-daterange-reports', {
                      active: fields[ 'report-set' ] === '2-2',
                    })}>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              selected={startDate}
                              selectsStart
                              startDate={startDate}
                              endDate={endDate}
                              placeholderText="Дата с"
                              onChange={(value) => {
                                this.handleChangeStart(value);
                              }}
                            />
                            <span className="icon-calendar"/>
                            <div className="help-block"/>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              selected={endDate}
                              selectsEnd
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                              placeholderText="Дата до"
                              onChange={(value) => this.handleChangeEnd(value)}
                            />
                            <span className="icon-calendar"/>
                            <div className="help-block"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </Form.Group>
            </Form>
          </div>
          <div className="form-row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <button
                  className={'btn btn-blue'}
                  onClick={this.props.history.goBack}
                >Вернуться к мероприятиям
                </button>
              </div>

            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <Button
                  type="submit"
                  className="btn btn-orange"
                  onClick={this.getLocation}
                >
                  Показать отчет
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  const { events: { price } } = state;
  return {
    price,
  };
}

const mapDispatchToProps = {
  getEventPricesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardType);
