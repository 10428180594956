import { DICTIONARY } from 'redux/types';

export default function dictionary (
  state = { lala: {} },
  action = {}
) {
  switch (action.type) {
    case DICTIONARY.set: {
      return {
        ...action.data,
      };
    }

    default:
      return state;
  }
}
