import { EVENTS } from 'redux/types';

const defaultState = {
  params: {},
};

export default function historyParams (state = defaultState, action = {}) {
  switch (action.type) {
    case EVENTS.GET_HISTORY_PARAMS: {
      return {
        ...state,
        params: action.params,
      };
    }
    case EVENTS.CLEAR_HISTORY_PARAMS: {
      return {
        ...state,
        params: {},
      };
    }
    default:
      return state;
  }
}
