const localStorage = window.localStorage;

export const LS = {
  get: (name) => {
    const value = localStorage.getItem(name);
    let parsedValue;
    try {
      parsedValue = JSON.parse(value);
    } catch (e) {
      return value;
    }
    return parsedValue;
  },
  set: (name, data) => {
    data = typeof data === 'string' ? data : JSON.stringify(data);
    localStorage.setItem(name, data);
  },
  remove: (name) => localStorage.removeItem(name),
  clear: localStorage.clear,
};
