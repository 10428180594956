import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import BasketList from 'components/BasketList';
import TicketActions from 'components/TicketActions';

class Basket extends Component {
  render () {
    return (
      <Switch>
        <Route path='/basket/:id' component={TicketActions}/>
        <Route path='/basket' component={BasketList}/>
      </Switch>

    );
  }
}

export default Basket;
