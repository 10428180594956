import { combineReducers } from 'redux';
import events from './events';
import basket from './basket';
import dictionary from './dictionary';
import references from './references';
import auth from './auth';
import orders from './orders';
import orderCreate from './orderCreate';
import orderCancel from './orderCancel';
import orderTickets from './orderTickets';
import reports from './reports';
import ticketTemplateList from './ticketTemplateList';
import historyParams from './historyParams';

export default combineReducers({
  events,
  basket,
  dictionary,
  references,
  auth,
  orders,
  orderCreate,
  orderCancel,
  orderTickets,
  reports,
  ticketTemplateList,
  historyParams,
});
