import { DICTIONARY } from 'redux/types';
import ruJSON from 'i18n/ru.json';

export const setDictionary = (lang) => (dispatch) => {
  let dataLang = {};

  switch (lang) {
    case 'ru':
      dataLang = ruJSON;
      break;
    default:
      dataLang = ruJSON;
  }
  return dispatch({
    type: DICTIONARY.set,
    data: dataLang,
  });
};
