import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { get, find } from 'lodash';

class SelectCustom extends Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    optionArray: PropTypes.array,
    onValueChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  };

  state = { value: '', text: '' };

  getSelected = () => {
    const value = this.props.hasOwnProperty('value')
      ? this.props.value
      : this.state.value;
    const option = find(this.props.optionArray, { value: parseInt(value) });
    return {
      value,
      text: get(option, 'text'),
    };
  };

  handleChange = (e, { value }) => {
    this.setState({ value });
    this.props.onValueChange(value);
  };

  render () {
    const selected = this.getSelected();

    return (

      <Dropdown
        selectOnBlur={false}
        selectOnNavigation={false}
        text={String(selected.value ? selected.text : this.props.placeholder)}
        onChange={this.handleChange}
        options={this.props.optionArray}
        placeholder={this.props.placeholder}
        className={this.props.className}
        selection
        search
        value={selected.value}
      />
    );
  }
}

export default SelectCustom;
