import { COMMON, ORDERS } from 'redux/types';

const defaultState = {
  list: [],
  loading: false,
  loaded: false,
  searchResult: [],
  error: null,
  errorMessage: false,
};

export default function orderList (state = defaultState, action = {}) {
  switch (action.type) {
    case ORDERS.GET + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }

    case ORDERS.GET + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    }

    case ORDERS.GET + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.response,
      };
    }

    case ORDERS.ORDER_SEARCH_ITEM + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }

    case ORDERS.ORDER_SEARCH_ITEM + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        searchResult: [],
        error: action.error,
        errorMessage: true,
        // errorMessage: action.errorMessage,
      };
    }

    case ORDERS.ORDER_SEARCH_ITEM + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        searchResult: [
          action.response,
        ],
        errorMessage: action.errorMessage,
      };
    }

    default:
      return state;
  }
}
