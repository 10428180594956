import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ColumnLeft extends Component{

  static propTypes = {
    children: PropTypes.any,
    style: PropTypes.object,
  };

  render () {
    const { children, style } = this.props;
    return <div className="content__left col-lg-8" style={style}>{ children }</div>
  }
}
export default ColumnLeft;
