export const DICTIONARY = {
  set: 'SET_DICTIONARY',
};

export const FILTER = {
  FILTERED_EVENTS_PARAMS: 'FILTERED_EVENTS_PARAMS',
};

export const BASKET = {
  GET: 'GET_BASKET',
  DELETE: 'TICKET_DELETE',
  ADD: 'TICKET_RESERVE',
};

export const EVENTS = {
  GET: 'GET_EVENTS',
  GET_PRICE_LIST: 'GET_PRICE_LIST',
  GET_HISTORY_PARAMS: 'GET_HISTORY_PARAMS',
  GET_TICKET_TEMPLATE_LIST: 'GET_TICKET_TEMPLATE_LIST',
  CATEGORIES: 'GET_EVENTS_CATEGORIES',
  CLEAR_HISTORY_PARAMS: 'CLEAR_HISTORY_PARAMS',
  CLEAR: 'CLEAR',
};

export const PLACES = {};

export const ORDERS = {
  GET: 'GET_ORDERS_LIST',
  ORDER_ITEM: 'GET_ORDER',
  ORDER_SEARCH_ITEM: 'ORDER_SEARCH_ITEM',
  ORDER_CREATE: 'ORDER_CREATE',
  ORDER_DELETE: 'ORDER_DELETE',
  ORDER_ENTITY_DELETE: 'ORDER_ENTITY_DELETE',
  ORDER_CANCEL_PRINTING: 'ORDER_CANCEL_PRINTING',
  ORDER_ENTITY_CANCEL_PRINTING: 'ORDER_ENTITY_CANCEL_PRINTING',
  PROMOTER_ORDER_PAY: 'PROMOTER_ORDER_PAY',
  PROMOTER_ORDER_CANCEL: 'PROMOTER_ORDER_CANCEL',
  PROMOTER_ORDER_ENTITY_RETURN: 'PROMOTER_ORDER_ENTITY_RETURN',
  PROMOTER_MNF_ORDER_CANCEL: 'PROMOTER_MNF_ORDER_CANCEL',
  PROMOTER_MNF_ORDER_ENTITY_RETURN: 'PROMOTER_MNF_ORDER_ENTITY_RETURN',
  ERROR: 'ORDERS_ERROR',
};

export const REPORTS = {
  GET_EVENT_COMMON_REPORT: 'GET_EVENT_COMMON_REPORT',
  GET_SALES_REPORT: 'GET_SALES_REPORT',
  GET_SEATS_REPORT: 'GET_SEATS_REPORT',
  CLEAR: 'CLEAR',
  ERROR: 'ERROR',
};

export const REFERENCES = {
  GET: 'GET_REFERENCES',
};

export const PAGINATION = {
  RECEIVE_TODO_PAGE: 'RECEIVE_TODO_PAGE',
};

export const SEARCH = {
  GET: 'get_search',
  LOADER_START: 'search_loader_start',
  LOADER_END: 'search_loader_end',
  listReceive: 'search_list_receive',
};

export const AUTH = {
  LOGIN: 'AUTH_LOGIN',
  GET_CLIENT: 'GET_CLIENT',
  LOGOUT: 'LOGOUT',
  ERROR: 'AUTH_ERROR',
};

export const COMMON = {
  START: '__START',
  SUCCESS: '__SUCCESS',
  ERROR: '__ERROR',
};
