import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import ColumnLeft from 'components/ColumnLeft';
import ColumnRight from 'components/ColumnRight';
import PlaceSelectPanel from 'components/RightSide/PlaceSelectPanel';
import SchemeOfPlaces from 'components/SchemeOfPlaces';
import DimmerLoader from 'components/DimmerLoader';
import withReferences from 'hocs/withReferences';
import { promiseByObject } from 'utils/helpers';
import api from 'server';

class PlaceSelect extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    references: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    seatLocksList: [],
    data: null,
    svg: null,
    selectedPlaces: null,
    loading: false,
    loaded: false,
    heightSet: window.innerHeight - 225,
  };

  componentDidMount () {
    // this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    return this.fetchData();
  }

  componentDidUpdate (prevProps) {
    const prevEvent = prevProps.match.params[ 'eventId' ];
    const currentEvent = this.props.match.params[ 'eventId' ];
    if (prevEvent !== currentEvent) {
      window.removeEventListener('resize', this.updateDimensions);
      return this.fetchData();
    }
  }

  instanceRef = null;

  setInstanceRef = (ref) => (this.instanceRef = ref);

  async fetchData () {
    const { match: { params: { eventId } } } = this.props;
    const response = await promiseByObject({
      seatLocksList: api.places.getSeatLock(),
      data: api.places.getPlaceFullInfo(eventId),
    });

    const { seatLocksList, data } = response;
    this.setState({
      seatLocksList,
      data,
      svg: data.schema.path,
      loaded: true,
      loading: false,
      selectedPlaces: null,
    });
  }

  parseSelectedPlace = (el) => ({
    price: el.parentNode.getAttribute('price_id'),
    name: el.getAttribute('name'),
    id: el.getAttribute('id'),
    maxPlaces: el.getAttribute('gsc_fl'),
    isGroup: !!el.getAttribute('gsc_fs'),
    idReserve: el.parentNode.getAttribute('sh_id'),
    node: el,
  });

  handleSelect = (elements) => {
    const selected = elements.map(this.parseSelectedPlace);
    this.setSelectedPlaces(selected);
  };

  setSelectedPlaces = (selectedPlaces) => this.setState({ selectedPlaces });

  svgUpdate = async () => {
    const { match: { params: { eventId } } } = this.props;
    const { path } = await api.places.getPlaceSVG(eventId);
    this.setState({
      svg: path,
      loading: false,
      selectedPlaces: null,
    });
  };

  handleReset = () => {
    const { selectedPlaces } = this.state;
    if (selectedPlaces && selectedPlaces.length) {
      this.instanceRef.deselect(selectedPlaces.map((el) => el.node));
    }
  };

  handleRemove = (id) => {
    const { selectedPlaces } = this.state;
    if (selectedPlaces && selectedPlaces.length) {
      const indexDelete = findIndex(selectedPlaces, { id });
      this.instanceRef.deselect(indexDelete);
    }
  };

  handleSelectGroup = (id) => {
    const { selectedPlaces } = this.state;
    if (selectedPlaces && selectedPlaces.length) {
      const groupIndex = findIndex(selectedPlaces, { id });
      api.places.getSeatInGroup({
        ticket_id: groupIndex,
      });
      this.setState({
        groupIndex: groupIndex,
      });
    }
  };

  updateDimensions = () => {
    this.setState({
      heightSet: window.innerHeight - 225,
    });
  };

  render () {
    const { loaded, loading, } = this.state;
    const { references, match, history } = this.props;
    if (!loaded) {
      return <DimmerLoader active={true}/>;
    }
    return (
      <div className="content">
        <DimmerLoader active={loading}/>
        <div className="container">
          <div className="row">
            <ColumnLeft>
              <SchemeOfPlaces
                {...this.state}
                references={references}
                instanceRef={this.setInstanceRef}
                onSelect={this.handleSelect}
                onSelectGroup={this.handleSelectGroup}
                match={match}
              />
            </ColumnLeft>
            <ColumnRight>
              <PlaceSelectPanel
                {...this.state}
                onRemove={this.handleRemove}
                references={references}
                onReset={this.handleReset}
                onLock={this.svgUpdate}
                onUnlock={this.svgUpdate}
                onPurchase={this.svgUpdate}
                history={history}
              />
            </ColumnRight>
          </div>
        </div>
      </div>
    );
  }
}

export default withReferences(PlaceSelect);
