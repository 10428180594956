import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class Field extends Component {
  static propTypes = {
    type: PropTypes.any,
  };

  getField () {
    const { type, ...rest } = this.props;
    switch (type) {
      case 'textarea':
        return <textarea
          {...rest}
        />;
      default:
        return <Form.Input
          {...rest}
          type={type}
        />;
    }
  }

  render () {
    return this.getField();
  }
}

export default Field;
