import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import OrderList from 'components/OrderList';
import OrderTickets from 'components/OrderTickets';
import TicketActions from 'components/TicketActions';

class Orders extends Component {
  render () {
    return (
      <Switch>
        <Route path='/orders/:id/ticket' component={TicketActions}/>
        <Route path='/orders/:id' component={OrderTickets}/>
        <Route path='/orders' component={OrderList}/>
      </Switch>

    );
  }
}

export default Orders;
