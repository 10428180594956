import { BASKET, COMMON } from 'redux/types';

const defaultState = {
  list: [],
  loading: false,
  loaded: false,
};

export default function basket (state = defaultState, action = {}) {
  switch (action.type) {
    case BASKET.GET + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }
    case BASKET.GET + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    }
    case BASKET.GET + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.response[ 'basket_entities' ],
        timeLive: action.response[ 'time_live' ],
      };
    }
    case BASKET.DELETE + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }
    case BASKET.DELETE + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case BASKET.DELETE + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        list: action.response[ 'basket_entities' ],
        timeLive: action.response[ 'time_live' ],
      };
    }
    default:
      return state;
  }
}
