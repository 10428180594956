import { EVENTS, COMMON } from 'redux/types';

const defaultState = {
  templateList: [],
  loading: false,
  loaded: false,
};

export default function events (state = defaultState, action = {}) {
  switch (action.type) {
    case EVENTS.GET_TICKET_TEMPLATE_LIST + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }
    case EVENTS.GET_TICKET_TEMPLATE_LIST + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    }
    case EVENTS.GET_TICKET_TEMPLATE_LIST + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        templateList: [ ...action.response, ...state.templateList ],
      };
    }

    default:
      return state;
  }
}
