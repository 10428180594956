import { ORDERS } from 'redux/types';
import api from 'server';

export const getOrders = (params) => ({
  type: ORDERS.GET,
  promise: api.orders.get(params),
});

export const orderCreate = (params) => ({
  type: ORDERS.ORDER_CREATE,
  promise: api.orders.orderCreate(params),
});

export const promoterOrderPay = (params) => ({
  type: ORDERS.PROMOTER_ORDER_PAY,
  promise: api.orders.promoterOrderPay(params),
});

export const orderDelete = (params) => ({
  type: ORDERS.ORDER_DELETE,
  promise: api.orders.orderDelete(params),
});

export const orderEntityDelete = (params) => ({
  type: ORDERS.ORDER_ENTITY_DELETE,
  promise: api.orders.orderEntityDelete(params),
});

export const getOrderSearch = (params) => ({
  type: ORDERS.ORDER_SEARCH_ITEM,
  promise: api.orders.order(params),
});
