import { timeFormat, dateFormat } from 'constants/dateFormats';
import React, { Fragment } from 'react';
import { set, isArray } from 'lodash';
import printJS from 'print-js';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import axios from 'axios';
import moment from 'moment';

// eslint-disable-next-line no-undef
const parser = new DOMParser();

export const encodeXML = (string) => encodeURIComponent(string);

export const parseXML = (string, querySelector) => {
  const html = parser.parseFromString(string, 'text/html');
  if (html) {
    if (querySelector) {
      const result = html.querySelector(querySelector);
      return result && result.textContent;
    }
    return html;
  }
};

export const paramsSerializer = function (params) {
  if (typeof params === 'object') {
    return Object.keys(params).map((key) =>
      `${encodeURIComponent(key)}=${encodeURIComponent(params[ key ])}`,
    ).join('&');
  }
};

function box (x, y, width, height) {
  if (x == null) {
    x = y = width = height = 0;
  }
  if (y == null) {
    y = x.y;
    width = x.width;
    height = x.height;
    x = x.x;
  }
  return {
    x: x,
    y: y,
    width: width,
    w: width,
    height: height,
    h: height,
    x2: x + width,
    y2: y + height,
    cx: x + width / 2,
    cy: y + height / 2,
  };
}

function isPointInsideBBox (bbox, x, y) {
  return (
    x >= bbox.x &&
    x <= bbox.x + bbox.width &&
    y >= bbox.y &&
    y <= bbox.y + bbox.height
  );
}

export function isBBoxIntersect (bbox1, bbox2) {
  bbox1 = box(bbox1);
  bbox2 = box(bbox2);
  return (
    isPointInsideBBox(bbox2, bbox1.x, bbox1.y) ||
    isPointInsideBBox(bbox2, bbox1.x2, bbox1.y) ||
    isPointInsideBBox(bbox2, bbox1.x, bbox1.y2) ||
    isPointInsideBBox(bbox2, bbox1.x2, bbox1.y2) ||
    isPointInsideBBox(bbox1, bbox2.x, bbox2.y) ||
    isPointInsideBBox(bbox1, bbox2.x2, bbox2.y) ||
    isPointInsideBBox(bbox1, bbox2.x, bbox2.y2) ||
    isPointInsideBBox(bbox1, bbox2.x2, bbox2.y2) ||
    (((bbox1.x < bbox2.x2 && bbox1.x > bbox2.x) ||
      (bbox2.x < bbox1.x2 && bbox2.x > bbox1.x)) &&
      ((bbox1.y < bbox2.y2 && bbox1.y > bbox2.y) ||
        (bbox2.y < bbox1.y2 && bbox2.y > bbox1.y)))
  );
}

export const getSeparatedTime = (string) => {
  const date = moment(string);
  return (
    <Fragment>
      <span>{date.format(dateFormat)} </span>
      <span>{date.format(timeFormat)}</span>
    </Fragment>
  );
};

// for translate descriptions from server
export const translate = (lang, description) =>
  (
    (description && description.length) &&
    description.find((item) => item.lang === lang)
  ) || {};

// Promise.all with resolve and reject in then(). catch() not call
export const PromiseAll = (promises) => (
  Promise.all(
    promises.map((promise) =>
      promise.catch((e) => e.error ? e : { error: e }),
    ),
  )
);

/**
 * @param {string[]} pages file paths
 * @return {Promise}
 */
export const print = (pages) => (
  new Promise((resolve, reject) => {
    try {
      if (isArray(pages)) {
        let count = 0;
        const print = () => {
          if (pages[ count ]) {
            printJS({ printable: pages[ count ], onPrintDialogClose: print });
            count++;
          } else {
            resolve();
          }
        };
        print();
      } else {
        printJS({ printable: pages, onPrintDialogClose: resolve });
      }
    } catch (e) {
      reject(e);
    }
  })
);

/**
 * @param {string[]} paths file paths
 * @param {string} zipName name for creating zip file
 * @returns {Promise}
 */
export const multipleDownload = (paths, zipName) => {
  const zip = new JSZip();
  for (const path of paths) {
    const fileName = path.match(new RegExp('([^/?]+)(?=/?(?:$))'))[ 0 ];
    const request = axios.get(path, {
      responseType: 'blob',
      headers: { 'Accept': 'application/pdf' },
    }).then((resp) => resp.data);
    zip.file(fileName, request);
  }
  return zip.generateAsync({ type: 'blob' })
    .then((content) => saveAs(content, `${zipName}.zip`));
};

export const getSeparatedPlace = (string) => (
  string.split('/').map((el) => {
    const element = el.split(':');
    return [ element[ 0 ], element[ 1 ] ];
  })
);

export const parseSearch = (string) =>
  string.slice(1).split('&').reduce((acc, el) => {
    const keyValue = el.split('=');
    acc[ decodeURIComponent(keyValue[ 0 ]) ] = decodeURIComponent(keyValue[ 1 ]);
    return acc;
  }, {});

/**
 * @param {Object} object object with promise values
 * @example
 * promiseByObject({ contacts: get('/contacts')});
 * @returns {Promise<object>} { contacts: [...contacts] }
 */
export const promiseByObject = async (object) => {
  const keys = Object.keys(object);
  const promises = keys.map((key) => object[ key ]);
  const result = await PromiseAll(promises);
  return result.reduce((acc, response, index) => (
    response.error
      ? set(acc, keys[ index ], null)
      : set(acc, keys[ index ], response)
  ), {});
};
