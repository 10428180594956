import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FilterTitle extends Component {
  static propTypes = {
    title: PropTypes.string,
    toggleOn: PropTypes.bool,
    callback: PropTypes.func,
  };

  state = {
    toggleOn: this.props.toggleOn,
  };

  render () {
    const { title, toggleOn, callback } = this.props;
    return (
      <div className="box-head">
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-8">
            <div className="box-title">{title}</div>
          </div>
          <div className="col-sm-4 text-right">
            <div
              className={`${toggleOn ? 'box-toggle js-box-toggle  active' : 'box-toggle js-box-toggle '}`}
              onClick={callback}
            >
              {toggleOn ? 'Свернуть фильтр' : 'Развернуть фильтр'}
              <span className="icon icon-angle-down" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
