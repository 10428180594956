import { REPORTS } from 'redux/types';
import api from 'server';

export const getEventCommonReport = (params) => ({
  type: REPORTS.GET_EVENT_COMMON_REPORT,
  promise: api.reports.getEventCommonReport({
    ...params,
    show_sold_by_ticketpro: true,
    show_manufactured_by_ticketpro: true,
    show_invitations_by_ticketpro: true,
    show_sold_by_promoter: true,
    show_manufactured_by_promoter: true,
    show_invitations_by_promoter: true,
  }),
});

export const getEventSalesReport = (params) => ({
  type: REPORTS.GET_EVENT_SALES_REPORT,
  promise: api.reports.getEventSalesReport({
    ...params,
    show_sold_by_ticketpro: true,
    show_manufactured_by_ticketpro: true,
    show_invitations_by_ticketpro: true,
    show_sold_by_promoter: true,
    show_manufactured_by_promoter: true,
    show_invitations_by_promoter: true,
  }),
});

export const getSeatsListReport = (params) => ({
  type: REPORTS.GET_SEATS_REPORT,
  promise: api.reports.getSeatsListReport(params),
});

export const clear = () => ({
  type: REPORTS.CLEAR,
});
