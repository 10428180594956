import { get } from 'server/implementation';
export default {
  /**
   * @param {string} min  optional
   * @param {string} max  optional
   * @param {integer} client_id  client's id from get_client_info
   * @param {Date} select_from  start date of event (default: new Date())
   * @param {Date} select_to  finish date of event
   */
  get: (params) => get('get-events-list', params),
  /**
   * @param {string} min  optional
   * @param {string} max  optional
   * @param {integer} client_id  client's id from get_client_info
   * @param {Date} select_from  start date of event (default: new Date())
   * @param {Date} select_to  finish date of event
   * @param {string($integer:id1,id2,...,idN)} cities  finish date of event
   * @param {string($integer:id1,id2,...,idN)} event_categories  List of event category identifiers separated by commas
   * @param {string($integer:id1,id2,...,idN)} venues  Venues List of Event IDs
   * @param {string($integer:id1,id2,...,idN)} events  List of Event IDs
   * @param {integer} event_type_id  Event Type Id
   * @param {integer} promoter_id  Event organizer ID
   * @param {string($length=300)} name_filter  Phrase to filter the event name
   * (Cyrillic, Latin, numeric and space characters are prohibited)
   */
  getPricesList: (params) => get('get-prices-list', params),
  /**
   * @param {string} min  optional
   * @param {string} max  optional
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} event_id  Event ID
   */
  getEventPricesList: (params) => get('get-event-prices-list', params),
  /**
   * @param {string} min  optional
   * @param {string} max  optional
   * @param {integer} client_id  client's id from get_client_info
   * @param {integer} event_id  Event ID
   */
  getEventTicketTemplateList: (params) => get('get-event-ticket-templs-list', params),
};
