import { REFERENCES, COMMON } from 'redux/types';

const defaultState = {
  data: null,
  loading: false,
  loaded: true,
};

export default function (state = defaultState, action = {}) {
  switch (action.type) {
    case REFERENCES.GET + COMMON.START:
      return {
        ...state,
        loading: true,
      };

    case REFERENCES.GET + COMMON.SUCCESS: {
      const { response } = action;
      const data = state.data || {};
      return {
        ...state,
        loaded: true,
        loading: false,
        data: {
          ...data,
          ...response,
        },
      };
    }

    default:
      return state;
  }
}
