import { REPORTS, COMMON } from 'redux/types';

const defaultState = {
  path: '',
  loading: false,
  loaded: false,
  error: null,
  errorMessage: null,
};

export default function reports (state = defaultState, action = {}) {
  switch (action.type) {
    case REPORTS.GET_EVENT_COMMON_REPORT + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }
    case REPORTS.GET_EVENT_COMMON_REPORT + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    }
    case REPORTS.GET_EVENT_COMMON_REPORT + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        path: action.response[ 'path' ],
      };
    }
    case REPORTS.GET_EVENT_SALES_REPORT + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }
    case REPORTS.GET_EVENT_SALES_REPORT + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    }
    case REPORTS.GET_EVENT_SALES_REPORT + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        path: action.response[ 'path' ],
      };
    }
    case REPORTS.GET_SEATS_REPORT + COMMON.START: {
      return {
        ...state,
        loading: true,
      };
    }
    case REPORTS.GET_SEATS_REPORT + COMMON.ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
        errorMessage: action.errorMessage,
      };
    }
    case REPORTS.GET_SEATS_REPORT:
      return {
        ...state,
        error: action.error,
        errorMessage: action.errorMessage,
      };
    case REPORTS.GET_SEATS_REPORT + COMMON.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        path: action.response[ 'path' ],
        errorMessage: action.errorMessage,
      };
    }
    case REPORTS.CLEAR: {
      return {
        ...state,
        loaded: false,
        path: '',
      };
    }
    default:
      return state;
  }
}
