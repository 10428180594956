// import { get } from 'server/implementation';
import events from 'server/modules/events';
import places from 'server/modules/places';
import references from 'server/modules/references';
import auth from 'server/modules/auth';
import basket from 'server/modules/basket';
import orders from 'server/modules/orders';
import reports from 'server/modules/reports';

export default {
  events,
  places,
  references,
  auth,
  basket,
  orders,
  reports,
};
