export const ticketProTickets = [
  {
    id: 11,
    name: 'show_sold_by_ticketpro',
    title: 'Продажи',
  },
  {
    id: 12,
    name: 'show_manufactured_by_ticketpro',
    title: 'Изгот. с ценой',
  },
  {
    id: 13,
    name: 'show_invitations_by_ticketpro',
    title: 'Изгот. с ценой 0,00 руб.',
  },
];
export const organizerTickets = [
  {
    id: 14,
    name: 'show_sold_by_promoter',
    title: 'Продажи',
  },
  {
    id: 15,
    name: 'show_manufactured_by_promoter',
    title: 'Изгот. с ценой',
  },
  {
    id: 16,
    name: 'show_invitations_by_promoter',
    title: 'Изгот. с ценой 0,00 руб.',
  },
];
