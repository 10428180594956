import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Menu extends Component {
  static propTypes = {
    active: PropTypes.any,
    close: PropTypes.func,
  };

  menu = [
    {
      id: '1',
      link: '/',
      text: 'Все мероприятия',
    },
    {
      id: '2',
      link: '/basket',
      text: 'Корзина',
    },
    {
      id: '4',
      link: '/orders',
      text: 'Заказы',
    },
    {
      id: '5',
      link: '/reports',
      text: 'Отчеты',
    },
    {
      id: '6',
      link: '/settings',
      text: 'Настройки',
    },
  ];

  onCloseMenu () {
    window.innerWidth < 1024 && this.props.close();
  }

  render () {
    return (
      <div className={this.props.active ? 'top-menu active' : 'top-menu'} data-toggle="top-menu">
        <div
          className="menu-close js-menu-close"
          data-toggle="top-menu"
          onClick={() => this.props.close()}
        >
          <span className="icon icon-close"></span>
        </div>
        <div className="container">
          <ul>
            {this.menu.map((item) =>
              <li key={item.id}>
                <Link to={item.link} onClick={this.onCloseMenu}>
                  {item.text}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default Menu;
